import React, { useEffect, useState } from "react";
import { TextInput } from "../../components/custom-inputs";
import { useForm } from "react-hook-form";
import { Box, Button, FormControl } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useCurrentMerchantSelector } from "../../@core/slice";
import { useTranslation } from "react-i18next";
import { FileUploader } from "react-drag-drop-files";
import { useEditSubCategory } from "../../@core/hooks/mutation/categories/sub-categories.hook";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import "./style.scss";

interface Props extends React.HTMLProps<HTMLDivElement> {
  closeModal: () => void;
  setShowModalEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedItem: React.Dispatch<any>;
  selectedItem: any;
}

export const EditSubCategoryForm: React.FC<Props> = ({
  closeModal,
  setShowModalEdit,
  selectedItem,
  setSelectedItem,
}) => {
  const { merchant } = useCurrentMerchantSelector();
  const { mutate: editSubCategory, isLoading: editLoading } =
    useEditSubCategory();
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [isImageChanged, setIsImageChanged] = useState(false);
  const EngLang = i18n.language === "en";

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      nameEn: EngLang
        ? selectedItem?.name || ""
        : selectedItem?.translation?.name || "",
      nameAr: EngLang
        ? selectedItem?.translation?.name || ""
        : selectedItem?.name || "",
      image: selectedItem?.image ?? [],
      parent: merchant?.category?._id||"",
      store: merchant?._id,
    },
  });

  useEffect(() => {
    if (selectedItem) {
      setValue(
        "nameEn",
        EngLang
          ? selectedItem.name || " "
          : selectedItem.translation?.name || " "
      );
      setValue(
        "nameAr",
        EngLang ? selectedItem.translation?.name || "" : selectedItem.name || ""
      );
      setValue("image", selectedItem.image);
    }
  }, [EngLang, selectedItem, setValue]);
  const watchImage = watch("image");

  const handleImageChange = (image: File) => {
    setValue("image", image);
    setIsImageChanged(true);
  };

  const onSubmitFormHandler = async (data: any) => {
    const formData = new FormData();
    formData.append("nameEn", data.nameEn);
    formData.append("nameAr", data.nameAr);
    formData.append("parent", data.parent);
    formData.append("store", data.store);
    if (isImageChanged) {
      if (data.image instanceof File) {
        formData.append("image", data.image);
      }
    }
    editSubCategory(
      { id: selectedItem._id, data: formData },
      {
        onSuccess: () => {
          toast.success(t("success.successfullyUpdated"));
          queryClient.invalidateQueries("sub-categories");
          setSelectedItem(null);
          setShowModalEdit(false);
        },
        onError: () => {
          toast.error(t("errors.faildUpdated"));
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmitFormHandler)}>
      <Box sx={{ marginBottom: "1rem" }}>
        <TextInput
          control={control}
          inputId="sub-category-name-Ar"
          name="nameAr"
          label={t("sub.SubCategoryNameِAr")}
        />
      </Box>
      <Box sx={{ marginBottom: "1rem" }}>
        <TextInput
          control={control}
          inputId="sub-category-nameEn"
          name="nameEn"
          label={t("sub.SubCategoryNameEn")}
        />
      </Box>

      <Box sx={{ marginBottom: "1rem" }}>
        <FormControl fullWidth>
          <FileUploader
            accept="image/*"
            handleChange={handleImageChange}
            types={["JPEG", "PNG"]}
          >
            <div
              style={{
                padding: "1.2rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px dashed #344767",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              {watchImage instanceof File ? (
                <div className="upload__image-list">
                  <div className="image-item">
                    <img
                      src={URL.createObjectURL(watchImage)}
                      alt="product"
                      width="100"
                      style={{
                        borderRadius: "8px",
                        height: "auto",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="">
                  <img
                    src={watchImage}
                    alt="product"
                    width="200px"
                    height={"200px"}
                    style={{
                      borderRadius: "2px",
                      height: "auto",
                      objectFit: "cover",
                      border: "1px solid #17171742",
                      padding: "5px",
                    }}
                  />
                </div>
              )}
              <div>
                <button
                  style={{
                    all: "unset",
                    color: "#1f5199",
                    borderRadius: "8px",
                    backgroundColor: "#e5effd",
                    padding: "0.6rem 0.6rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  {t("product.ChangeImages")}
                </button>
                <p>{t("product.Accepts")} .png and .jpg</p>
              </div>
            </div>
          </FileUploader>
        </FormControl>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "12px",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <LoadingButton
          type="submit"
          className="buttonHandleSubmit"
          size="large"
          loading={editLoading}
          disabled={editLoading}
          variant="contained"
        >
          {t("sub.EditSubCategory")}
        </LoadingButton>

        <Button
          type="button"
          className="formButton"
          variant="outlined"
          size="large"
          onClick={() => closeModal()}
        >
          {t("sub.Cancel")}
        </Button>
      </Box>
    </form>
  );
};
