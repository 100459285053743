import { useQuery } from 'react-query';
import { ApiResponse, getAllSizes } from '../../../api/sizes/sizes.api';
interface UseSizesQueryParams {
  limit?: number;
  skip?: number;
}

export const useSizesQuery = ({ limit, skip }: UseSizesQueryParams) => {
  return useQuery<ApiResponse, Error>(
    ["sizes", skip, limit],
    () => getAllSizes({ skip, limit }),
  );
}