import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
// export const addTimeToDate = (date: Dayjs, time: Dayjs) => {
//   const dateInstance = dayjs(date).isValid()
//     ? dayjs(date).utc()
//     : dayjs().utc();
//   const timeInstance = dayjs(time).isValid()
//     ? dayjs(time).utc()
//     : dayjs().utc();

//   const value = dateInstance
//     .set("hour", timeInstance.hour())
//     .set("minute", timeInstance.minute())
//     .set("second", timeInstance.second())
//     .set("millisecond", timeInstance.millisecond());

//   return value.utc();
// };
export const addTimeToDate = (date: any, time: any) => {
  return dayjs(date).hour(dayjs(time).hour()).minute(dayjs(time).minute());
};
