import React from "react";
import { Link } from "react-router-dom";

//styles
import "./style.scss";
import { useTranslation } from "react-i18next";

function RestConfirmation() {
  const { t } = useTranslation();
  return (
    <div className="resetConfirmationContainer">
      <div className="container">
        <div className="svgContainer">
          <img src="/confirmation.png" alt="" style={{ width: '200px', marginTop: '30px' }} />
        </div>
        <div className="content">
          <h3>{t("auth.PasswordResetSuccessfully")}</h3>
          <span>
            {t("auth.Click")} <Link to="/login"> {t("auth.here")}</Link>{" "}
            {t("auth.toLogAccount")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default RestConfirmation;
