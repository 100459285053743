import { Button, Paper } from "@mui/material";
import React, { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  NavBar,
  SubNavBar,
  MediaCardList,
  MediaCard,
  FloatingForm,
  NoData,
} from "../../components";
import { AddTagsForm } from "./add-tags-form.component";
import { useTagsQuery } from "../../@core/hooks/query/tag/tags.hook";
import {
  useAddTags,
  useDeleteTags,
  useEditTags,
} from "../../@core/hooks/mutation/tags/tag.mutate";
import { useQueryClient } from "react-query";
import { AddTagParams } from "../../@core/api/tags/tags.api";
import { ConfirmModal } from "../../components/confirm-modal/confirm-modal.component";
import { SelectChangeEvent } from "@mui/material/Select";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { Pagination } from "../../components/custom-pagination/customPagination";

interface Props extends React.HTMLProps<HTMLDivElement> {}

export const TagsPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState<number | undefined>(5);
  const skip = limit ? page * limit : undefined;
  const {
    data: tagsData,
    isLoading: loadingTag,
    error,
  } = useTagsQuery({ skip, limit });

  const { mutate: deleteTag, isLoading: isDeleting } = useDeleteTags();
  const { mutate: editTag } = useEditTags();
  const { mutate: addTag, isLoading } = useAddTags();
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState<any>(null);

  const [showErrorModal, setShowErrorModal] = useState(false);

  const errorMessage = useRef("");
  const openAddModalHandler = (isEditMode = false) => {
    if (!isEditMode) {
      setSelectedItem(null);
    }

    setShowModal(true);
  };

  const onSubmitFormHandler = (data: AddTagParams) => {
    const isEditMode = selectedItem !== null;
    if (isEditMode) {
      editTag(
        { id: selectedItem._id, data },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("tags");
            toast.success(t("success.successfullyUpdated"));
            setSelectedItem(null);
            setShowModal(false);
          },
          onError: () => {
            toast.error(t("errors.faildUpdated"));
          },
        }
      );
    } else {
      addTag(data, {
        onSuccess: () => {
          queryClient.invalidateQueries("tags");
          toast.success(t("success.successfullyAdd"));
          setSelectedItem(null);
          setShowModal(false);
        },
        onError: () => {
          toast.error(t("errors.faildAdd"));
        },
      });
    }
  };

  const openConfirmDelete = (selectedItem: any) => {
    setShowConfirmModal(true);
    setSelectedItem(selectedItem);
  };

  const openEditForm = (selectedItem: any) => {
    setShowModal(true);
    setSelectedItem(selectedItem);
  };

  const onDelete = (id: string) => {
    deleteTag(id, {
      onSuccess: () => {
        toast.success(t("success.successfullyDelated"));
        queryClient.invalidateQueries("tags");
        setSelectedItem(null);
        setShowConfirmModal(false);
      },
      onError(error: any, variables, context) {
        toast.error(t("errors.faildDelated"));
        setShowConfirmModal(false);
        errorMessage.current = error.message;
      },
    });
  };
  const numberOfPages = tagsData?.numberOfPages || 0;
  const numberOfRecords = tagsData?.numberOfRecords || 0;
  const handleLimitChange = (event: SelectChangeEvent<number>) => {
    const newLimit = Number(event.target.value) || undefined;
    setLimit(newLimit);
    setPage(0);
  };
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };
  if (loadingTag) {
    return (
      <Fragment>
        <NavBar title={t("menuNavigation.tags")} />
        <SubNavBar>
          <Button
            onClick={() => openAddModalHandler()}
            variant="contained"
            size="large"
            className="buttonHandleSubmit"
            disableElevation
          >
            {t("menuNavigation.addTags")}
          </Button>
        </SubNavBar>
        <section className="page-container">
          <LoadingSpinner />
        </section>
      </Fragment>
    );
  }

  if (error) {
    toast.error(error?.message);
  }
  const EmptyData = (
    <NoData
      title={t("tag.titleNoData")}
      description={t("tag.DescripNoData")}
      buttonText={t("tag.AddTag")}
      buttonClickHandler={openAddModalHandler}
    />
  );

  return (
    <>
      <NavBar title={t("menuNavigation.tags")} />

      <SubNavBar>
        <Button
          onClick={() => openAddModalHandler()}
          variant="contained"
          size="large"
          className="buttonHandleSubmit"
          disableElevation
        >
          {t("menuNavigation.addTags")}
        </Button>
      </SubNavBar>
      {tagsData?.data?.length === 0 ? (
        EmptyData
      ) : (
        <>
          <section className="page-container">
            <Paper elevation={3} sx={{ padding: "1rem" }}>
              <>
                <MediaCardList>
                  {tagsData?.data?.map((tag) => (
                    <div>
                      <MediaCard
                        key={tag._id}
                        title={tag.name}
                        subCategory={tag.subCategory.name}
                        onDelete={() => openConfirmDelete(tag)}
                        onEdit={() => openEditForm(tag)}
                      />
                    </div>
                  ))}
                </MediaCardList>
              </>
            </Paper>
            <Paper
              elevation={1}
              sx={{
                position: "fixed",
                bottom: 20,
                left: "50%",
                transform: "translateX(-50%)",
                padding: "1rem",
                minWidth: "md",
                textAlign: "center",
              }}
            >
              <Pagination
                currentPage={page}
                numberOfPages={numberOfPages}
                onPageChange={handlePageChange}
                totalRecords={numberOfRecords}
                limit={limit!}
                onLimitChange={handleLimitChange}
              />
            </Paper>
          </section>
        </>
      )}

      <FloatingForm
        visible={showModal}
        setVisible={() => {
          setShowModal(false);
        }}
        title={selectedItem ? t("tag.EditTag") : t("tag.AddTag")}
        btnName={selectedItem ? t("tag.EditTag") : t("tag.AddTag")}
        body={
          <AddTagsForm
            selectedItem={selectedItem}
            submitFormHandler={onSubmitFormHandler}
            closeModal={() => {
              setSelectedItem(null);
              setShowModal(false);
            }}
            isSubmitting={isLoading}
          />
        }
      />

      <ConfirmModal
        title={t("tag.DeleteTag")}
        description={t("tag.DescriptionDelet")}
        isOpen={showConfirmModal}
        handleConfirm={() => onDelete(selectedItem._id)}
        handleClose={() => setShowConfirmModal(false)}
        isLoading={isDeleting}
      />

      <ConfirmModal
        title={t("tag.ErrDelete")}
        description={errorMessage.current}
        isOpen={showErrorModal}
        handleClose={() => {
          setShowErrorModal(false);
          errorMessage.current = "";
        }}
        isLoading={false}
      />
    </>
  );
};
