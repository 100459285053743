import React from "react";
import "./style.scss"
import { Box, Select, MenuItem } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";


interface PaginationProps {
  currentPage: number;
  numberOfPages: number;
  onPageChange: (page: number) => void;
  onLimitChange: (event: SelectChangeEvent<number>) => void;
  limit: number;
  totalRecords: number;
}

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  numberOfPages,
  onPageChange,
  onLimitChange,
  limit,
  totalRecords,
}) => {
  return (
    <div className="pagination-container">
      <Box>
        {Array.from({ length: numberOfPages }, (_, index) => (
          <button
            key={index}
            onClick={() => onPageChange(index)}
            disabled={index === currentPage}
            className="pagination-button"
          >
            {index + 1}
          </button>
        ))}
      </Box>
      {/* Items per page select */}
      <Box className="items-per-page-select">
        <Select
          id="limit"
          value={limit}
          onChange={onLimitChange}
          variant="standard"
          className="select-field"
        >
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={totalRecords}>All</MenuItem>
        </Select>
      </Box>
    </div>
  );
};


