import React from "react";

//Styles
import "./style.scss";

//Icons
import { CgLogOut } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

//Hooks
import { useDispatch } from "react-redux";
import { logOut, useCurrentUserSelector } from "../../@core/slice";
import { LanguageSwitcher } from "../lang/language-switcher";
import { useTranslation } from "react-i18next";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import { ProfileMenu } from "./profile-menue";
import { getStatusTranslation } from "../../pages/orders/statusHelper";
type NavbarProps = {
  title: string;
  status?: string;
  orderPaid?: boolean;
  hasBack?: boolean;
};

function NavBar({
  title,
  status,
  hasBack = false,
  orderPaid = false,
}: NavbarProps) {
  const { user } = useCurrentUserSelector();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const getInitials = (name: any) => {
    if (!name) return "";
    return name.charAt(0);
  };

  return (
    <div className="navbarContainer">
      <div>
        {hasBack && (
          <div onClick={() => navigate(-1)}>
            {isRTL ? (
              <>
                <FaArrowRight size={20} />
              </>
            ) : (
              <FaArrowLeft size={20} />
            )}
          </div>
        )}
        <h2>{title}</h2>
        {status && (
          <span
            className={`${
              status === "Pending"
                ? "pendingStatus"
                : status === "Delivered"
                ? "approvedStatus"
                : status === "Accepted" || status === "Packing"
                ? "acceptedStatus"
                : status === "Approved"
                ? "approvedStatus"
                : status === "Cancelled"
                ? "cancelledStatus"
                : ""
            }`}
          >
            {getStatusTranslation(status, t)}
          </span>
        )}
        {orderPaid && (
          <span className="approvedStatus">{t("navigationBar.paid")}</span>
        )}
      </div>

      <div className="prof-header">
        <div>
          <LanguageSwitcher />
        </div>
        <div>
          <div className="profile">
            <Tooltip title={t("profile.AccountSettings")}>
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <>
                  {user?.user?.avatar &&
                  typeof user.user.avatar === "string" ? (
                    <Avatar
                      sx={{ width: 32, height: 32 }}
                      src={user?.user?.avatar}
                      alt="User Avatar"
                    />
                  ) : (
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        backgroundColor: "#ffc96b",
                        color: "#3d2800",
                      }}
                    >
                      {getInitials(user?.user?.name)}
                    </Avatar>
                  )}
                </>
              </IconButton>
            </Tooltip>
            <h3 className="userName">{user?.user?.name}</h3>
            <div className="logouticon">
              {isRTL ? (
                <CgLogOut onClick={() => dispatch(logOut())} size={26} />
              ) : (
                <CgLogOut
                  onClick={() => dispatch(logOut())}
                  style={{ transform: "rotate(180deg)" }}
                  size={26}
                />
              )}
            </div>
            <ProfileMenu
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
