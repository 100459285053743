import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React from "react";
import { Controller, FieldError } from "react-hook-form";
import "./style.scss";
import { SelectInput } from "../select-input/select.component";
interface Props {
  name: string;
  inputId: string;
  label: string;
  control: any;
  options?: { label: string; value: string }[];
  rules?: any;
  readonly?: boolean;
  error?: FieldError;
}

export const ChipInput: React.FC<Props> = ({
  inputId,
  name,
  label,
  options = [],
  control,
  rules = {},
  error,
}) => {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field }) => (
        <>
          <InputLabel
            sx={{ textAlign: "initial !important" }}
            className="label"
            id={`${inputId}-multiple-chip-label`}
          >
            {label}
          </InputLabel>
          <FormControl sx={{ width: "100%" }} error={!!error}>
            <Select
              sx={{
                borderRadius: "12px",
              }}
              placeholder={label}
              {...field}
              input={
                <OutlinedInput
                  id={`${inputId}-select-multiple-chip`}
                  //   label={label}
                />
              }
              displayEmpty
              renderValue={(selected: any[]) => {
               if (
                 !selected ||
                 (Array.isArray(selected) && selected.length === 0)
               ) {
                 return (
                   <span
                     style={{
                       color: "#aaa",
                       fontSize: "14px",
                     }}
                   >
                     {label}
                   </span>
                 );
               }
                const getOptionLabel = (value: string) => {
                  const option = options?.find(
                    (option) => option.value === value
                  );
                  return option?.label;
                };

                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                    }}
                  >
                    {selected?.map((value) => (
                      <Chip key={value} label={getOptionLabel(value)} />
                    ))}
                  </Box>
                );
              }}
              multiple
            >
              {options?.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            {!!error ? <FormHelperText>{error?.message}</FormHelperText> : null}
          </FormControl>
        </>
      )}
    />
  );
};
