import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
export const ImageUploader = ({ onChange, value }: any) => {
  const [preview, setPreview] = useState(value);
  const [isDragging, setIsDragging] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (value instanceof Blob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(value);
    } else if (typeof value === "string") {
      setPreview(value);
    } else {
      setPreview(null);
    }
  }, [value]);

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        onChange(file);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        onChange(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = () => {
    setIsDragging(true);
  };
  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleRemoveImage = () => {
    setPreview(null);
    onChange(null);
  };

  return (
    <div
      style={{
        padding: "1.2rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        border: isDragging ? "2px solid #2196F3" : "1px dashed #344767",
        borderRadius: "8px",
        cursor: "pointer",
        backgroundColor: isDragging ? "#E3F2FD" : "transparent",
      }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {preview ? (
        <Stack>
          <div className="upload__image-list">
            <div className="image-item">
              <img
                src={preview}
                alt="product"
                width="100"
                style={{
                  borderRadius: "8px",
                  height: "auto",
                  maxWidth: "300px",
                  maxHeight: "300px",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <button
            className="btnImage"
            type="button"
            onClick={handleRemoveImage}
          >
            {t("product.Remove")}
          </button>
        </Stack>
      ) : (
        <div>
          <Box textAlign={"center"}>
            <div>
              <Button
                variant="contained"
                component="label"
                className="upload__image-dropzone-btn btnImage"
              >
                {t("store.AddFiles")}
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleImageChange}
                />
              </Button>
              <Typography
                sx={{ color: "#6D7175", my: 1 }}
                className="image-accept"
              >
                {t("product.Accepts")} .png and .jpg
              </Typography>
            </div>
          </Box>
        </div>
      )}
    </div>
  );
};
