import { useMutation } from 'react-query'
import { addSize, deleteSize, editSize } from '../../../api/sizes/sizes.api';

export const useAddSizes = () => {
	return useMutation(addSize);
}

export const useDeleteSize = () => {
	return useMutation(deleteSize);
};

export const useEditSize = () => {
	return useMutation(editSize);
};
