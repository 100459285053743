import React from 'react';
import './media-card.component.scss';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

interface Props extends React.HTMLProps<HTMLDivElement> {
	title: string;
	subCategory?: string;
	imageUrl?: string;
	onDelete?: () => void;
	onEdit?: () => void;
}

export const MediaCard: React.FC<Props> = ({
	title = 'Title here..',
	subCategory,
	imageUrl,
	onDelete,
	onEdit,
}) => {
	const {t}=useTranslation()
	const mediaImage = imageUrl ? (
		<div className='media-card__image'>
			<img src={imageUrl} alt={title} />
		</div>
	) : null;

	return (
		<article className='media-card'>
			<div className='media-card__content'>
				{mediaImage}
				<div className='media-card__titles'>
					<h3>{title}</h3>
					{subCategory ? (
						<p>
							{t("product.Subcategorey")}:
							<strong>{subCategory}</strong>
						</p>
					) : null}
				</div>
			</div>

			<div className='media-card__options'>
				{onEdit && (
					<button
						aria-label='edit'
						onClick={onEdit}>
						<BiEdit size={20} />
					</button>
				)}

				{onDelete && (
					<button
						className='delete-btn'
						aria-label='delete'
						onClick={onDelete}>
						<BiTrash size={20} />
					</button>
				)}
			</div>
		</article>
	);
};
