import React from 'react';
import './media-card-list.component.scss';

interface Props extends React.HTMLProps<HTMLDivElement> {}

export const MediaCardList: React.FC<Props> = ({
	children,
}) => {
	return (
		<div className='media-card-list'>{children}</div>
	);
};
