import axios from "../../../utils/axios.util";
import { StoreTag } from "../../models";
import { GetPageParams } from "../../models/page/page.model";
import { Category, SubCategory } from "../sizes/sizes.api";

export interface AddTagParams {
  name: string;
  category: string;
  subCategory: string;
}
export interface Tag {
  _id: string;
  category: Category;
  subCategory: SubCategory;
  name: string;
}
export interface ApiResponseTags {
  success: boolean;
  data: Tag[];
  currentPage: number;
  numberOfPages: number;
  numberOfRecords: number;
}
export interface ApiResponseSub {
  success: boolean;
  data: SubCategory[];
  currentPage: number;
  numberOfPages: number;
  numberOfRecords: number;
}

export const getAllTags = async (params: GetPageParams): Promise<ApiResponseTags> => {
  const { skip, limit } = params;
  const response = await axios.get<ApiResponseTags>("/tag", {
    params: {
      skip: skip ?? undefined,
      limit: limit ?? undefined,
      paginate: !!(skip !== undefined && limit !== undefined),
    },
  });
  return response.data;
};
export const getTagByCategoryId = async (
  params: GetPageParams & { categoryId: string }
) => {
  const res = await axios.get<{ data: StoreTag[] }>(`/tag/category`, {
    params,
  });

  return res.data;
};

export const addTag = ({ name, category, subCategory }: AddTagParams) => {
  return axios.post("/tag", {
    name,
    category,
    subCategory,
  });
};

export const editTag = ({ id, data }: { id: string; data: AddTagParams }) => {
  return axios.patch(`/tag/${id}`, data);
};

export const deleteTag = (id: string) => {
  return axios.delete(`/tag/${id}`);
};
