import axios from '../../../utils/axios.util';
import { GetPageParams } from '../../models/page/page.model';
import { Category, SubCategory } from '../sizes/sizes.api';

interface AddColorParams {
	name: string;
	code: string;
	category: string;
	subCategory: string;
}
export interface Color {
  _id: string;
  category: Category;
  subCategory: SubCategory;
  name: string;
}
export interface ApiResponseColors {
  success: boolean;
  data: Color[];
  currentPage: number;
  numberOfPages: number;
  numberOfRecords: number;
}
export const getAllColors = async (
  params: GetPageParams
): Promise<ApiResponseColors> => {
  const { skip, limit } = params;
  const response = await axios.get<ApiResponseColors>("/color", {
    params: {
      skip: skip ?? undefined,
      limit: limit ?? undefined,
      paginate: !!(skip !== undefined && limit !== undefined),
    },
  });
  return response.data;
};
export const addColor = ({
	name,
	code,
	category,
	subCategory,
}: AddColorParams) => {
	return axios.post('/color', {
		name,
		code,
		category,
		subCategory,
	});
};

export const editColor = ({
	id,
	data,
}: {
	id: string;
	data: Partial<AddColorParams>;
}) => {
	return axios.patch(`/color/${id}`, data);
};

export const deleteColor = (id: string) => {
	return axios.delete(`/color/${id}`);
};
