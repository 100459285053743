import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Stack,
  Button,
  FormControl,
  Avatar,
  IconButton,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { PhotoCamera } from "@mui/icons-material";
import { updateUser, useCurrentUserSelector } from "../../@core/slice";
import { useUpdateSeller } from "../../@core/hooks";
import { TextInput } from "../custom-inputs";
import { CountryCodeInput } from "../../pages/signup/components/countryCodeInput.common";
import "./style.scss"
interface Props extends React.HTMLProps<HTMLDivElement> {
  closeModal: () => void;
}

export const EditSeller: React.FC<Props> = ({ closeModal }) => {
  const { t } = useTranslation();
  const { user } = useCurrentUserSelector();
  const { mutate: editSeller } = useUpdateSeller();
  const dispatch = useDispatch();
  const [avatarChanged, setAvatarChanged] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: user?.user?.name || "",
      countryCode: user?.user?.countryCode || "",
      phoneNumber: user?.user?.phoneNumber || "",
      avatar: user?.user?.avatar || "",
    },
  });

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setValue("avatar", e.target.files[0]);
      setAvatarChanged(true);
    }
  };

  const onSubmit = (values: any) => {
    const body = new FormData();
    body.append("name", values.name ?? "");
    body.append("countryCode", values.countryCode ?? "");
    body.append("phoneNumber", values.phoneNumber ?? "");
    if (avatarChanged && values.avatar) {
      body.append("avatar", values.avatar);
    }
    editSeller(
      { body },
      {
        onSuccess: (data) => {
          toast.success(t("success.successfullyUpdated"));
          dispatch(updateUser({ user: data?.data }))
          closeModal();
        },
        onError: () => {
          toast.error(t("errors.faildUpdated"));
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {/* seller avatar */}
          <Grid item xs={12} md={12}>
            <Box
              width={"100%"}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <div>
                <FormControl fullWidth>
                  <Box display="flex" alignItems="center">
                    <Controller
                      name="avatar"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Avatar
                            src={
                              typeof field.value === "string"
                                ? field.value
                                : URL.createObjectURL(field.value)
                            }
                            alt="avatar"
                            sx={{ width: 110, height: 110 }}
                          />
                          <IconButton
                            aria-label="upload picture"
                            component="label"
                            sx={{
                              position: "absolute",
                              bottom: "-3%",
                              right: "-12%",
                            }}
                          >
                            <input
                              type="file"
                              hidden
                              accept="image/*"
                              onChange={handleAvatarChange}
                            />
                            <PhotoCamera />
                          </IconButton>
                        </>
                      )}
                    />
                  </Box>
                </FormControl>
                <FormHelperText error={!!errors.avatar}>
                  {errors.avatar
                    ? (errors.avatar.message as React.ReactNode)
                    : null}
                </FormHelperText>
              </div>
            </Box>
          </Grid>

          {/* seller Name */}
          <Grid item xs={12} md={6}>
            <TextInput
              name="name"
              inputId="name"
              type="string"
              label={t("auth.Name")}
              rules={{
                required: {
                  value: true,
                  message: t("auth.NameRequired"),
                },
              }}
              control={control}
              error={errors?.name}
              inputProps="text"
            />
          </Grid>

          {/* seller phone */}
          <Grid item xs={12} md={6}>
            <InputLabel
              sx={{ textAlign: "initial !important" }}
              className="label"
              htmlFor={"phoneNumber"}
            >
              {t("emp.phoneNumber")}
            </InputLabel>
            <FormControl fullWidth>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                <CountryCodeInput
                  id="countryCode"
                  label=""
                  errors={errors}
                  control={control}
                />
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t("emp.PhoneRequired"),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      className="textField"
                      sx={{ width: "90% !important", gap: 1 }}
                      {...field}
                      inputProps={{ inputMode: "text" }}
                      type="string"
                      placeholder=""
                      error={!!errors.phoneNumber}
                    />
                  )}
                />
              </Box>
              <FormHelperText error={!!errors.phoneNumber}>
                {errors.phoneNumber?.message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ mt: 5 }}
            >
              <Button
                sx={{ mx: 3 }}
                type="button"
                className="formButton"
                size="large"
                variant="outlined"
                color="error"
                onClick={closeModal}
              >
                {t("emp.Cancel")}
              </Button>
              <Button
                type="submit"
                className="buttonHandleSubmit"
                size="large"
                variant="contained"
                color="basePrimary"
              >
                {t("profile.EditProfile")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
