import { Box, Button, FormHelperText, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import {
  useCreateDiscount,
  useListallProducts,
  useStore,
} from "../../@core/hooks";
import { useSubcategories } from "../../@core/hooks/query/discount/get-sub-categories.hook";
import { DiscountFormData } from "../../@core/models";
import { StoreDiscountTypesEnum } from "../../@core/models/discount/discount-types.enum";
import { useCurrentMerchantSelector } from "../../@core/slice";
import { DatePickerInput } from "../../components/date-picker/date-picker-input.component";
import { TimePickerInput } from "../../components/time-picker/time-picker.component";
import { addTimeToDate } from "../../utils/dayjs.util";
import { PercentageNumber } from "../../utils/regex-patterns.util";
import { StoreDiscountTypes } from "./discount-types";
import { useTranslation } from "react-i18next";
import { SelectInput } from "../../components/custom-inputs";
import { toast } from "react-toastify";
import "./style.scss";
import dayjs from "dayjs";

interface Props extends React.HTMLProps<HTMLDivElement> {
  closeModal: () => void;
}

export const AddDiscountForm: React.FC<Props> = ({ closeModal }) => {
  const { data: store } = useStore();
  const { mutate: createDiscount, isLoading } = useCreateDiscount();
  const { data: productsList } = useListallProducts({
    storeId: store?.data?._id || "",
    paginated: false,
  });
  const { merchant } = useCurrentMerchantSelector();
  const { data: subCategories } = useSubcategories(store?.data?.category?._id);
  const optionsSubCategories = subCategories?.data?.map((item) => ({
    label: item?.name,
    value: item?._id,
  }));
  const optionsProduct = productsList?.data?.map((item: any) => ({
    label: item?.name,
    value: item?._id,
  }));

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<DiscountFormData>({
    defaultValues: {
      type: "",
      store: store?.data?._id,
      discount: undefined,
      image: undefined,
      category: store?.data?.category?._id,
      subCategory: "",
      product: "",
      startDate: dayjs(),
      endDate: dayjs().add(1, "day"),
      startTime: dayjs("00:00", "HH:mm"),
      endTime: dayjs("00:00", "HH:mm"),
    },
  });

  const typeChange = watch("type");
  const watchImage = watch("image");

  const handleImageChange = (file: File) => {
    setValue("image", file);
  };

  const onSubmit = (data: DiscountFormData) => {
    const startDate = addTimeToDate(
      data.startDate || new Date(),
      data.startTime || new Date().getTime()
    ).toISOString();
    const endDate = addTimeToDate(
      data.endDate || new Date(),
      data.endTime || new Date().getTime()
    ).toISOString();
    delete data.startTime;
    delete data.endTime;

    // remove startTime and endTime from data
    const body = {
      ...data,
      store: store?.data._id,
      discount: data?.discount,
      product: data?.product,
      category: store?.data?.category?._id,
      subCategory: data?.subCategory,
      startDate,
      endDate,
    };

    if (data.type === "STORE_DISCOUNT") {
      delete body.category;
      delete body.subCategory;
      delete body.product;
    } else if (data.type === "CATEGORY_DISCOUNT") {
      delete body.subCategory;
      delete body.product;
    } else if (data.type === "SUB_CATEGORY_DISCOUNT") {
      delete body.product;
    } else if (data.type === "PRODUCT_DISCOUNT") {
      delete body.subCategory;
    }

    const formData = new FormData();

    Object.entries(body).forEach(([key, value]) => {
      formData.set(key, value);
    });

    queryClient.invalidateQueries([`${merchant?._id}/discounts`, []]);
    createDiscount(
      {
        storeId: store?.data?._id || "",
        body: formData,
      },
      {
        onSuccess: () => {
          toast.success(t("success.successfullyAdd"));
          queryClient.invalidateQueries({
            queryKey: [`discount/store/${merchant?._id}`, []],
          });
          queryClient.invalidateQueries("discount");
          closeModal();
        },
        onError: () => {
          toast.error(t("errors.faildAdd"));
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            {/* Discount Type */}
            <SelectInput
              name="type"
              size="medium"
              inputId="discount-type-select-label"
              label={t("app.DiscountType")}
              control={control}
              options={StoreDiscountTypes()}
              error={errors?.type}
              rules={{
                required: {
                  value: true,
                  message: t("app.DiscountRequired"),
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {/* Discount Amount */}
            {/* <FormControl fullWidth> */}
            <Controller
              name="discount"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("app.DiscountAmountRequired"),
                },
                pattern: {
                  value: PercentageNumber,
                  message: t("app.AmountDiscr"),
                },
              }}
              render={({ field }) => (
                <>
                  <InputLabel
                    sx={{ textAlign: "initial !important" }}
                    className="label"
                    htmlFor={field.name}
                  >
                    {t("app.DiscountAmount")}
                  </InputLabel>
                  <TextField
                    {...field}
                    inputProps={{
                      inputMode: "numeric",
                    }}
                    fullWidth
                    type="number"
                    placeholder="10%"
                    className="textField"
                    error={!!errors.discount}
                  />
                </>
              )}
            />
            <FormHelperText error={!!errors.discount}>
              {errors?.discount?.message || null}
            </FormHelperText>

            {/* </FormControl> */}
          </Grid>

          {typeChange === StoreDiscountTypesEnum.SUB_CATEGORY_DISCOUNT && (
            <>
              <Grid item xs={12}>
                {/* Sub Category */}
                <SelectInput
                  name="subCategory"
                  size="medium"
                  inputId="sub-category-select-label"
                  label={t("app.SubCategory")}
                  control={control}
                  options={optionsSubCategories}
                  error={errors?.subCategory}
                  rules={{
                    required: {
                      value: true,
                      message: t("errors.required"),
                    },
                  }}
                />
              </Grid>
            </>
          )}

          {typeChange === StoreDiscountTypesEnum.PRODUCT_DISCOUNT && (
            <Grid item xs={12}>
              <SelectInput
                name="product"
                size="medium"
                inputId="product-select-label"
                label={t("app.Product")}
                control={control}
                options={optionsProduct}
                error={errors?.product}
                rules={{
                  required: {
                    value: true,
                    message: t("errors.required"),
                  },
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            {/* <FormControl fullWidth> */}
            <DatePickerInput
              name="startDate"
              label={t("app.StartDate")}
              rules={{
                required: {
                  value: true,
                  message: t("app.StartDateRequired"),
                },
              }}
              control={control}
              error={errors?.startDate}
            />
            {/* </FormControl> */}
          </Grid>

          <Grid item xs={12} md={6}>
            {/* <FormControl fullWidth> */}
            <TimePickerInput
              name="startTime"
              label={t("app.StartTime")}
              control={control}
              // rules={{
              //   required: {
              //     value: true,
              //     message: t("app.StartTimeRequired"),
              //   },
              // }}
              error={errors?.startTime}
            />
            {/* </FormControl> */}
          </Grid>

          <Grid item xs={12} md={6}>
            {/* <FormControl fullWidth> */}
            <DatePickerInput
              name="endDate"
              label={t("app.EndDate")}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("app.EndDateRequired"),
                },
              }}
              error={errors?.endDate}
            />
            {/* </FormControl> */}
          </Grid>

          <Grid item xs={12} md={6}>
            {/* <FormControl fullWidth> */}
            <TimePickerInput
              name="endTime"
              label={t("app.EndTime")}
              control={control}
              // rules={{
              //   required: {
              //     value: true,
              //     message: t("app.EndTimeRequired"),
              //   },
              // }}
              error={errors?.endTime}
            />
            {/* </FormControl> */}
          </Grid>

          <Grid item xs={12}>
            <InputLabel
              sx={{ textAlign: "initial !important" }}
              className="label"
              variant="standard"
            >
              {t("app.AddDiscountImage")}
            </InputLabel>
            <FormControl fullWidth>
              <FileUploader
                accept="image/*"
                handleChange={handleImageChange}
                types={["JPEG", "PNG", "GIF"]}
              >
                <div className="file-uploader-body">
                  <button className="btn">{t("product.AddFiles")} </button>
                  {watchImage instanceof File ? (
                    <p>
                      {t("app.FileName")}: {watchImage?.name}
                    </p>
                  ) : (
                    <img src={watchImage} alt="" />
                  )}
                  <p>{t("product.Accepts")} .gif, .png and .jpg</p>
                </div>
              </FileUploader>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              gap={3}
            >
              <Button
                onClick={closeModal}
                type="button"
                size="large"
                className="formButton"
                variant="outlined"
                disabled={isLoading}
              >
                {t("product.Cancel")}
              </Button>
              <Button
                type="submit"
                className="buttonHandleSubmit"
                size="large"
                variant="contained"
              >
                {t("app.AddDiscount")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
