import { CreateEmployee } from "../../../api";
import { EmployeeResponse } from "../../../models";
import { useCustomMutation } from "../use-mutation.hook";

export const useCreateEmployee = () => {
  return useCustomMutation<any, EmployeeResponse>({
    url: "store/store-employee",
    api: CreateEmployee,
  });
};
