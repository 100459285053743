import { Register } from "../../../api/auth";
import { RegisterDto, RegisterResponse } from "../../../models/auth/register";
import { useCustomMutation } from "../use-mutation.hook";

export const useRegister = () => {
  return useCustomMutation<RegisterDto, RegisterResponse>({
    url: "/auth/register",
    api: Register,
  });
};
