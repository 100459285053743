import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRegister } from "../../@core/hooks";
import { loginSuccess } from "../../@core/slice";

//styles
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import { SignupSellerFormData } from "../../@core/models";
import { theme } from "../../assets/styles/mui-theme";
import CustomePhoneNumber from "./components/PhoneNumber";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { CountryCodeInput } from "./components/countryCodeInput.common";

function SignUp() {
  const { mutate: register } = useRegister();
  const navigte = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onSubmit = (values: SignupSellerFormData) => {
    register(
      {
        email: values.email,
        password: values.password,
        name: values.name,
        countryCode: values.countryCode,
        phoneNumber: values.phoneNumber,
      },
      {
        onSuccess: (data) => {
          dispatch(loginSuccess(data));
          toast.success(`${t("auth.Welcome")} ${data.user?.name ?? ""}`);
          navigte("/");
        },
        onError: () => {
          toast.error(t("auth.faildRegister"));
        },
      }
    );
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupSellerFormData>({
    defaultValues: {
      name: "",
      email: "",
      countryCode: "",
      phoneNumber: "",
      password: "",
    },
  });

  return (
    <div className="signUpContainer">
      <div className="container">
        <div className="logo">
          <img src="/logoDark.png" alt="logo" />
          <span>BASE</span>
        </div>
        <div>
          <h3>{t("auth.AccountRegistration")}</h3>
          <h5>{t("auth.PleaseCompleteFeildbelow")}</h5>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t("auth.NameRequired"),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        size="small"
                        {...field}
                        label={t("auth.Name")}
                        inputProps={{
                          inputMode: "text",
                        }}
                        type="string"
                        placeholder=""
                        error={!!errors.name}
                      />
                    )}
                  />
                  <FormHelperText error={!!errors.name}>
                    {errors.name?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t("emp.Emailrequired"),
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t("emp.Pattern"),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        size="small"
                        {...field}
                        label={t("auth.Email")}
                        inputProps={{
                          inputMode: "text",
                        }}
                        type="string"
                        placeholder=""
                        error={!!errors.email}
                      />
                    )}
                  />
                  <FormHelperText error={!!errors.email}>
                    {errors.email?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>

                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                  }}>
                    <CountryCodeInput
                      id="countryCode"
                      label=""
                      errors={errors}
                      control={control}
                    />
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: t("emp.phoneRequired"),
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          size="small"
                          sx={{
                            width: '90% !important',
                            gap: 1
                          }}
                          {...field}
                          label={t("Phone Number")}
                          inputProps={{
                            inputMode: "text",
                          }}
                          type="string"
                          placeholder=""
                          error={!!errors.phoneNumber}
                        />
                      )}
                    />
                  </Box>
                  <FormHelperText error={!!errors.phoneNumber}>
                    {errors.phoneNumber?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t("emp.PasswordRequired"),
                      },
                      pattern: {
                        // regex to validate password with at least one lowercase letter, one uppercase letter, one digit, one special character, and is at least 8 characters long
                        value:
                          /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,64})$/,
                        message: t("emp.patternPasswored"),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        size="small"
                        {...field}
                        label={t("auth.Password")}
                        inputProps={{
                          inputMode: "text",
                        }}
                        type="password"
                        placeholder=""
                        error={!!errors.password}
                      />
                    )}
                  />
                  <FormHelperText error={!!errors.password}>
                    {errors.password?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <br />
          <Grid item xs={12} maxWidth={"35%"} style={{ marginLeft: 300 }}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="basePrimary"
                fullWidth
              >
                {t("auth.SignUp")}
              </Button>
              <div>
                {t("auth.HaveAccount")}
                <Link to="/login"> {t("auth.SignIN")}</Link>
              </div>
            </Stack>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default SignUp;

