import { useQuery } from 'react-query';
import { ApiResponseColors, getAllColors } from '../../../api/colors/colors.api';
import { CategoryResponse } from '../../../models';
import { useCustomQuery } from '../use-query.hook';
interface UseQueryParams {
  limit?: number;
  skip?: number;
}
export const useListColor = () =>
	useCustomQuery<CategoryResponse>({
		url: 'color?paginate=false',
	});

export const useGetAllColors = ({ limit, skip }: UseQueryParams) => {
  return useQuery<ApiResponseColors, Error>(["colors", skip, limit], () =>
    getAllColors({ skip, limit })
  );
};