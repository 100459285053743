import React from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { MuiColorInput } from 'mui-color-input';
import { InputLabel } from '@mui/material';
import "./style.scss";

interface Props {
	name: string;
	inputId: string;
	label: string;
	control: any;
	rules?: any;
	disabled?: boolean;
	multiline?: boolean;
	error?: FieldError;
	[key: string]: any;
}

export const ColorPickerInput: React.FC<Props> = ({
	inputId,
	name,
	label,
	control,
	rules = {},
	disabled = false,
	error,
	...rest
}) => {
	return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field }) => (
        <>
          <InputLabel
            sx={{ textAlign: "initial !important" }}
            className="label"
            htmlFor={field.name}
          >
            {label}
          </InputLabel>
          <MuiColorInput
            {...field}
            id={`${inputId}`}
            fullWidth
            disabled={disabled}
            error={!!error}
            className="textField"
            helperText={error?.message}
            {...rest}
          />
        </>
      )}
    />
  );
};
