import { Fragment, useEffect, useMemo, useState } from "react";
import {
  FilterHeader,
  FloatingForm,
  NavBar,
  NoData,
  Table,
} from "../../components";
import "./style.scss";

//Icons
import { useTranslation } from "react-i18next";
import { FiEdit } from "react-icons/fi";
import { useListDiscount } from "../../@core/hooks";
import { Discount as DiscountModel } from "../../@core/models";
import { useCurrentMerchantSelector } from "../../@core/slice";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { AddDiscountForm } from "./add-discount-form.component";
import { EditDiscountForm } from "./edit-discount-form.component";
import { getTranslatedDiscountType } from "./discount-types";

function Discount() {
  const { t, i18n } = useTranslation();
  const [showEditDiscountForm, setShowEditDiscountForm] = useState(false);
  const [showAddDiscountForm, setShowAddDiscountForm] = useState(false);
  const [page, setPage] = useState(0);
  const [selectedDiscount, setSelectedDiscount] = useState<DiscountModel>();
  const { merchant } = useCurrentMerchantSelector();
  const [status, setStatus] = useState("0");
  const [type, setType] = useState("0");

  const isRtl = i18n.dir() === "rtl";
  const isActive = useMemo(() => {
    return status === "1" ? true : status === "2" ? false : undefined;
  }, [status]);

  const isType = useMemo(() => {
    return type === "1"
      ? "STORE_DISCOUNT"
      : type === "2"
      ? "CATEGORY_DISCOUNT"
      : type === "3"
      ? "SUB_CATEGORY_DISCOUNT"
      : type === "4"
      ? "PRODUCT_DISCOUNT"
      : undefined;
  }, [type]);

  const {
    data: discounts,
    isLoading,
    refetch: RefetchDiscountList,
  } = useListDiscount({
    storeId: merchant?._id ?? "",
    limit: 10,
    page,
    isActive: isActive,
    type: isType,
  });
  useEffect(() => {
    RefetchDiscountList();
  }, [RefetchDiscountList, page, status, type]);

  const columns = useMemo(
    () => [
      {
        title: t("discount.type"),
        selector: (row: DiscountModel) => getTranslatedDiscountType(row?.type,t),
      },
      {
        title: t("discount.amount"),
        selector: (row: DiscountModel) => `${row.discount} %`,
      },
      {
        title: t("discount.status"),
        selector: (row: DiscountModel) => (
          <div className={isRtl ? "divstatusRTl" : ""}>
            <span
              className={`${
                row.isActive
                  ? isRtl
                    ? "approvedStatusRtl"
                    : "approvedStatus"
                  : isRtl
                  ? "cancelledStatusRTl"
                  : "cancelledStatus"
              }`}
            >
              {row.isActive ? t("app.Active") : t("app.Inactive")}
            </span>
          </div>
        ),
      },
      {
        title: `${t("discount.startOn")} (${t("discount.dateTime")})`,
        selector: (row: DiscountModel) =>
          new Date(row.startDate).toLocaleString(),
      },
      {
        title: `${t("discount.endOn")} (${t("discount.dateTime")})`,
        selector: (row: DiscountModel) =>
          new Date(row.endDate).toLocaleString(),
      },
      {
        title: t("discount.action"),
        selector: (row: DiscountModel) => (
          <div className={isRtl ? "flexActionsRtl" : "flexActions"}>
            <FiEdit
              size={20}
              onClick={() => {
                setSelectedDiscount(row);
                setShowEditDiscountForm(true);
              }}
            />
          </div>
        ),
      },
    ],
    [isRtl, t]
  );

  const openAddModalHandler = () => {
    setShowAddDiscountForm(true);
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (name === "status") setStatus(value);
    if (name === "TypeDiscont") setType(value);
  };

  if (isLoading) {
    return (
      <Fragment>
        <NavBar title={t("menuNavigation.discounts")} />
        <div className="tableContainer" id="tableContainer">
          <Table columns={columns} data={[]}>
            <LoadingSpinner />
          </Table>
        </div>
      </Fragment>
    );
  }
  const EmptyData = (
    <NoData
      title={t("app.titleNoDataDiscount")}
      description={t("app.DescripNoDataDiscount")}
      buttonText={t("app.AddDiscount")}
      buttonClickHandler={openAddModalHandler}
    />
  );
  return (
    <div>
      <NavBar title={t("menuNavigation.discounts")} />
      <FilterHeader
        //  Select Box
        hasSearch={false}
        hasStatuspluse={true}
        selectorPlaceHolderpluse={t("FilterByType")}
        selectorNamepluse="TypeDiscont"
        selectorValuepluse={status}
        selectorOnChangepluse={handleChange}
        selectorDataplus={() => [
          { _id: 0, name: t("app.AllTypes") },
          { _id: 1, name: t("app.StoreDiscount") },
          { _id: 2, name: t("app.CategoryDiscount") },
          { _id: 3, name: t("app.SubCategoryDiscount") },
          { _id: 4, name: t("app.ProductDiscount") },
        ]}
        // Select Box
        selectorName="status"
        selectorPlaceHolder={t("Filter by status")}
        selectorValue={status}
        selectorOnChange={handleChange}
        selectorData={() => [
          { _id: 0, name: t("app.AllStatus") },
          { _id: 1, name: t("app.Active") },
          { _id: 2, name: t("app.Inactive") },
        ]}
        // Add Button
        hasAdd={true}
        onAdd={() => setShowAddDiscountForm(true)}
      />
      {Array.isArray(discounts?.data) && discounts?.data.length === 0 ? (
        EmptyData
      ) : (
        <div className="tableContainer" id="tableContainer">
          <Table
            columns={columns}
            data={(discounts?.data as any[]) ?? []}
            handlePageChange={(selectedItem) => setPage(selectedItem.selected)}
            pageCount={discounts?.numberOfPages ?? 0}
            initialPage={page}
          />
        </div>
      )}

      {/* Add Discount Form */}
      <FloatingForm
        visible={showAddDiscountForm}
        setVisible={() => setShowAddDiscountForm(false)}
        title={t("app.AddDiscount")}
        body={
          <AddDiscountForm closeModal={() => setShowAddDiscountForm(false)} />
        }
        btnName={t("app.AddDiscount")}
      />

      {/* Edit Discount Form */}
      <FloatingForm
        visible={showEditDiscountForm}
        setVisible={() => setShowEditDiscountForm(false)}
        title={t("app.EditDiscount")}
        body={
          <EditDiscountForm
            selectedDiscount={selectedDiscount}
            closeModal={() => {
              setSelectedDiscount(undefined);
              setShowEditDiscountForm(false);
            }}
          />
        }
        btnName={t("app.EditDiscount")}
      />
    </div>
  );
}

export default Discount;
