import instance from "../../../utils/axios.util";
import { Product } from "../../models";

interface UpdateSellerDto {
  body: Product;
}

export async function UpdateSeller({ body }: UpdateSellerDto) {
  try {
    const res = await instance.patch(`/update`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}
