import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";

export const ImageUploaderMultiple = ({ onChange, value = [] }: any) => {
  const [previews, setPreviews] = useState<string[]>([]);
  const [isDragging, setIsDragging] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (Array.isArray(value)) {
      const fileReaders = [];
      const previewUrls: string[] = [];

      value.forEach((file) => {
        if (file instanceof Blob) {
          const reader = new FileReader();
          fileReaders.push(reader);
          reader.onloadend = () => {
            previewUrls.push(reader.result as string);
            if (previewUrls.length === value.length) {
              setPreviews(previewUrls);
            }
          };
          reader.readAsDataURL(file);
        } else if (typeof file === "string") {
          previewUrls.push(file);
        }
      });

      if (previewUrls.length === value.length) {
        setPreviews(previewUrls);
      }
    } else {
      setPreviews([]);
    }
  }, [value]);

  const handleImageChange = (e: any) => {
    const files = Array.from(e.target.files);
    const newFiles = [...value, ...files];
    const newPreviews: any[] = [];

    files.forEach((file: any) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        newPreviews.push(reader.result as string);
        if (newPreviews.length === files.length) {
          setPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
        }
      };
      reader.readAsDataURL(file);
    });

    onChange(newFiles);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files);
    const newFiles = [...value, ...files];
    const newPreviews: any[] = [];

    files.forEach((file: any) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        newPreviews.push(reader.result as string);
        if (newPreviews.length === files.length) {
          setPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
        }
      };
      reader.readAsDataURL(file);
    });

    onChange(newFiles);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = () => {
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleRemoveImage = (index: number) => {
    const newPreviews = previews.filter((_, i) => i !== index);
    const newFiles = value.filter((_: any, i: number) => i !== index);
    setPreviews(newPreviews);
    onChange(newFiles);
  };

  return (
    <div
      style={{
        padding: "1.2rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        border: isDragging ? "2px solid #2196F3" : "1px dashed #344767",
        borderRadius: "8px",
        cursor: "pointer",
        backgroundColor: isDragging ? "#E3F2FD" : "transparent",
      }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      <Box textAlign={"center"}>
        <Button
          variant="contained"
          component="label"
          className="upload__image-dropzone-btn btnImage"
        >
          {t("store.AddFiles")}
          <input
            type="file"
            accept="image/*"
            hidden
            multiple
            onChange={handleImageChange}
          />
        </Button>
        <Typography sx={{ color: "#6D7175", my: 1 }} className="image-accept">
          {t("product.Accepts")} .png and .jpg
        </Typography>
      </Box>
      <Stack direction="row" spacing={2} className="upload__image-list">
        {previews.map((preview, index) => (
          <div key={index} className="image-item">
            <img
              src={preview}
              alt={`product-${index}`}
              style={{
                borderRadius: "8px",
                height: "auto",
                maxWidth: "300px",
                maxHeight: "300px",
                objectFit: "contain",
              }}
            />
            <button
              className="btnImage"
              type="button"
              onClick={() => handleRemoveImage(index)}
            >
              {t("product.Remove")}
            </button>
          </div>
        ))}
      </Stack>
    </div>
  );
};
