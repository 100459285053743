//Styles
import { Button } from "@mui/material";
import "./style.scss";
import { useTranslation } from "react-i18next";

type EmptyContentProps = {
  title: string;
  hasButton?: boolean;
  buttonName?: string;
  onClick?: () => void;
};

function EmptyContent({
  title,
  hasButton,
  buttonName,
  onClick,
}: EmptyContentProps) {
  const {t}=useTranslation()
  return (
    <div className="emptyContentContainer">
      <div>
        <img src="/empty.svg" alt="" />
      </div>
      <div className="title">{title}</div>
      {hasButton && (
        <Button
          size="large"
          variant="contained"
          color="basePrimary"
          onClick={onClick}
        >
         {t("app.Create Store")}
        </Button>
      )}
    </div>
  );
}

export default EmptyContent;
