import instance from "../../../utils/axios.util";

interface UpdateOrderProps {
  storeId: string;
  status: string;
  orderId: string;
}

export async function UpdateOrderStatus({
  storeId,
  status,
  orderId,
}: UpdateOrderProps) {
  try {
    const res = await instance.patch(`${storeId}/order/${status}/${orderId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
