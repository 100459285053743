import { Grid, Button, Box } from "@mui/material";
import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { CgFileDocument } from "react-icons/cg";
import { LoadingButton } from "@mui/lab";

import { useSubcategories } from "../../../@core/hooks";
import NavBar from "../../NavBar";
import {
  TextInput,
  SelectInput,
  ChipInput,
  UploadImagesPreview,
} from "../../custom-inputs";
import { CategoryResponse } from "../../../@core/models";
import { useTranslation } from "react-i18next";
import { useTagByCategoryIdQuery } from "../../../@core/hooks/query/tag/tags.hook";
import { useNavigate } from "react-router-dom";
import "./add-product.scss"
interface Props extends React.HTMLProps<HTMLDivElement> {
  submitForm: (data: any) => void;
  storeId: string;
  isSubmitting: boolean;
  categories: CategoryResponse | undefined;
  foodCategoryId: string;
}

export const AddFoodProductFormTemplate: React.FC<Props> = ({
  submitForm,
  storeId,
  isSubmitting,
  categories,
  foodCategoryId,
}) => {
  const { t } = useTranslation();
  const navigte = useNavigate();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      store: storeId,
      isMenuProduct: true,
      category: foodCategoryId,
      subCategory: null,
      currency: "KWD",
      sku: "",
      nameAr: "",
      nameEn: "",
      descriptionAr: "",
      descriptionEn: "",
      price: "",
      mainImage: undefined,
      images: [],
      tags: [],
      addOns: [
        {
          nameAr: "",
          nameEn: "",
          price: "",
          image:null,
        },
      ],
    },
  });

  const addOnsField = useFieldArray<any>({
    name: "addOns",
    control,
  });

  const watchedSubCategory = watch("subCategory");

  const { data: subCategories } = useSubcategories(foodCategoryId);

  const { data: tags } = useTagByCategoryIdQuery({
    categoryId: watchedSubCategory ? watchedSubCategory : foodCategoryId,
  });
  const categoriesList = categories?.data?.map((category) => ({
    label: category.name,
    value: category._id,
  }));

  const subCategoriesList = subCategories?.data?.map((subCategory: any) => ({
    label: subCategory.name,
    value: subCategory._id,
  }));

  const tagsList = tags?.data?.map((tag: any) => ({
    label: tag.name,
    value: tag._id,
  }));

  const addAnotherAddOn = () => {
    addOnsField.append({
      nameAr: "",
      nameEn: "",
      price: "",
      image: null,
    });
  };

  const submitHandler = () => {
    submitForm(getValues());
  };

  return (
    <>
      <NavBar
        title={t("product.CreateFoodProduct")}
        status={""}
        hasBack={true}
      />

      <div className="content">
        <form onSubmit={handleSubmit(submitHandler)}>
          {/* Basic Details */}
          <section className="form-section">
            <header className="header">
              <CgFileDocument />
              {t("product.BasicDetails")}
            </header>

            <div className="section-body">
              <Grid container spacing={2} mb={4}>
                {/* category */}
                <Grid item xs={6}>
                  <SelectInput
                    inputId="categories"
                    name={"category"}
                    label={t("product.Category")}
                    options={categoriesList}
                    control={control}
                    readonly
                  />
                </Grid>

                {/* sub category */}
                <Grid item xs={6}>
                  <SelectInput
                    inputId="sub-categories"
                    name={"subCategory"}
                    label={t("product.Subcategory")}
                    options={subCategoriesList}
                    rules={{
                      required: {
                        value: true,
                        message: t("product.Required"),
                      },
                    }}
                    control={control}
                    error={errors.subCategory}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} mb={4}>
                {/* product name ar */}
                <Grid item xs={6}>
                  <TextInput
                    inputId="product-name-ar"
                    name="nameAr"
                    label={t("product.ProductNameArabic")}
                    rules={{
                      required: {
                        value: true,
                        message: t("product.Required"),
                      },
                    }}
                    control={control}
                    error={errors.nameAr}
                  />
                </Grid>

                {/* product name en */}
                <Grid item xs={6}>
                  <TextInput
                    inputId="product-name-en"
                    name="nameEn"
                    label={t("product.ProductNameEnglish")}
                    rules={{
                      required: {
                        value: true,
                        message: t("product.Required"),
                      },
                    }}
                    control={control}
                    error={errors.nameEn}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={4}>
                {/* Price */}
                <Grid item xs={6}>
                  <TextInput
                    type="number"
                    inputId="price"
                    name="price"
                    label={t("product.Price")}
                    rules={{
                      required: {
                        value: true,
                        message: t("product.Required"),
                      },
                    }}
                    control={control}
                    error={errors.price}
                  />
                </Grid>
                {/* SKU */}
                <Grid item xs={6}>
                  <TextInput
                    inputId="sku"
                    name="sku"
                    label={t("product.SKU")}
                    control={control}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} mb={4}>
                <Grid item xs={12}>
                  <TextInput
                    inputId="descriptionAr"
                    name="descriptionAr"
                    label={t("product.Description Arabic")}
                    control={control}
                    rows={4}
                    rules={{
                      required: {
                        value: true,
                        message: t("product.Required"),
                      },
                    }}
                    error={errors.descriptionAr}
                    multiline
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextInput
                    inputId="descriptionEn"
                    name="descriptionEn"
                    label={t("product.Description English")}
                    control={control}
                    rows={4}
                    rules={{
                      required: {
                        value: true,
                        message: t("product.Required"),
                      },
                    }}
                    error={errors.descriptionEn}
                    multiline
                  />
                </Grid>
              </Grid>
            </div>
          </section>

          {/* Tags */}
          <section className="form-section">
            <header className="header">
              <CgFileDocument />
              <h2>{t("product.Tags")}</h2>
            </header>

            <div className="section-body">
              <Grid container>
                <Grid item xs={12}>
                  {/* tags */}
                  <Grid item xs={12}>
                    <ChipInput
                      inputId="tags"
                      name="tags"
                      label={t("product.Tags")}
                      options={tagsList}
                      control={control}
                      rules={{
                        validate: (value: string[]) => {
                          return (
                            value.length > 0 ||
                            t("product.At least one tag is required")
                          );
                        },
                      }}
                      error={errors.tags as any}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </section>

          {/* Add Ons */}
          <section className="form-section">
            <header className="header">
              <CgFileDocument />
              <h2>{t("product.Add ons")}</h2>
            </header>

            <div className="section-body">
              {addOnsField?.fields?.map((field, index) => (
                <div className="addon-item">
                  <Grid key={field.id} container spacing={2} mb={4}>
                    <Grid item xs={4}>
                      <TextInput
                        inputId="add-on-name-ar"
                        name={`addOns.${index}.nameAr`}
                        label={t("product.NameArabic")}
                        control={control}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextInput
                        inputId="add-on-name-en"
                        name={`addOns.${index}.nameEn`}
                        label={t("product.NameEnglish")}
                        control={control}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextInput
                        type="number"
                        inputId="add-on-price"
                        name={`addOns.${index}.price`}
                        label={t("product.price")}
                        placeholder="10KD"
                        rules={{
                          required: {
                            value: true,
                            message: t("product.Required"),
                          },
                        }}
                        control={control}
                      />
                    </Grid>

                    <Grid margin={"auto"} my={2} item xs={12} md={8}>
                      <UploadImagesPreview
                        name={`addOns.${index}.image`}
                        label={t("product.Image")}
                        isMultiple={false}
                        control={control}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        className="formButton"
                        color="error"
                        onClick={() => addOnsField.remove(index)}
                      >
                        {t("product.Remove")}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ))}
              <Box display="flex" justifyContent="flex-end" gap={2}>
                <Button
                  onClick={() => addAnotherAddOn()}
                  variant="contained"
                  className="buttonHandleSubmit"
                  disableElevation
                >
                  {t("product.Add another add on")}
                </Button>
              </Box>
            </div>
          </section>

          {/* Cover Image */}
          <section className="form-section">
            <header className="header">
              <CgFileDocument />
              <h2>{t("product.CoverImage")}</h2>
            </header>

            <div className="section-body">
              <UploadImagesPreview
                name="mainImage"
                label={t("product.CoverImage")}
                rules={{
                  required: {
                    value: true,
                    message: t("product.Required"),
                  },
                }}
                control={control}
                error={errors?.mainImage as any}
              />
            </div>
          </section>

          {/* Meal Images */}
          <section className="form-section">
            <header className="header">
              <CgFileDocument />
              <h2>{t("product.MealImages")}</h2>
            </header>

            <div className="section-body">
              <UploadImagesPreview
                name="images"
                label={t("product.MealImages")}
                isMultiple={true}
                control={control}
              />
            </div>
          </section>

          {/* Submit Buttons */}
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button
              type="button"
              className="formButton"
              variant="outlined"
              disableElevation
              onClick={() => navigte(`/products`)}
            >
              {t("product.Cancel")}
            </Button>

            <LoadingButton
              type="submit"
              className="buttonHandleSubmit"
              variant="contained"
              loading={isSubmitting}
              loadingPosition="start"
              disableElevation
            >
              {t("product.Save")}
            </LoadingButton>
          </Box>
        </form>
      </div>
    </>
  );
};
