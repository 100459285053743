import instance from "../../../utils/axios.util";
import { ICreateEmployeeDto } from "../../models";

interface CreateEmployeeProps {
  storeId: string;
  body?: ICreateEmployeeDto;
  id?: string;
}

export async function CreateEmployee({ storeId, body }: CreateEmployeeProps) {
  try {
    const res = await instance.post(`${storeId}/store-employee`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}
export async function UpdateEmployee({
  id,
  storeId,
  body,
}: CreateEmployeeProps) {
  try {
    const res = await instance.patch(`${storeId}/store-employee/${id}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}
export async function DeleteEmployee({ id, storeId }: CreateEmployeeProps) {
  try {
    const res = await instance.delete(`${storeId}/store-employee/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
