import { CreateProduct } from "../../../api";
import { ProductResponse } from "../../../models";
import { useCustomMutation } from "../use-mutation.hook";

export const useCreateProduct = () => {
  return useCustomMutation<any, ProductResponse>({
    url: "create/store/product",
    api: CreateProduct,
  });
};
