import { ListOrderResponse } from "../../../models";
import { useCustomQuery } from "../use-query.hook";

interface Props {
storeId: string;
  page?: number;
  limit?: number;
  paginated?: boolean;
  search?: string;
  status?: string | undefined;
}

export const useListOrders = ({
  storeId,
  page = 1,
  limit = 10,
  paginated = true,
  search = "",
  status,
}: Props) => {
  return useCustomQuery<ListOrderResponse>({
    url: `${storeId}/order`,
    page,
    limit,
    search,
    paginated,
    status,
  });
};
