import axios from "../../../utils/axios.util";
import { Category, SubCategory } from "../../models";
import { GetPageParams, Page } from "../../models/page/page.model";

interface GetCategoriesParams {
  skip?: number;
  limit?: number;
  order?: "ASCENDING" | "DESCENDING";
  search?: string;
  paginate?: boolean;
}
interface Parent {
  _id: string;
  name: string;
}

interface Item {
  translation: any;
  _id: string;
  name: string;
  image: string;
  parent: Parent;
}

export interface ApiResponseSubCatg {
  success: boolean;
  data: Item[];
  currentPage: number;
  numberOfPages: number;
  numberOfRecords: number;
}
export const getAllCategories = (getCategoryParams: GetCategoriesParams) => {
  return axios.get<Page<Category[]>>(`/seller/category`, {
    params: getCategoryParams,
  });
};

export const getSubcategories = async (
  mainCategoryId?: string,
  params?: GetPageParams
): Promise<ApiResponseSubCatg> => {
  try {
    const { skip, limit } = params || {};
    const res = await axios.get<ApiResponseSubCatg>(`/category/sub-category`, {
      params: {
        skip: skip ?? undefined,
        limit: limit ?? undefined,
        paginate: !!(skip !== undefined && limit !== undefined),
      },
    });

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to fetch subcategories");
  }
};
export const postSubCategory = async (subCategory: FormData) => {
  try {
    const res = await axios.post<SubCategory>(
      `/category/sub-category`,
      subCategory
    );

    return res.data;
  } catch (error) {
    console.error(error);
  }
};
export const editSubCategory = ({
  id,
  data,
}: {
  id: string;
  data: any;
}) => {
  return axios.patch(`/category/${id}`, data);
};

export const deleteSubCategory = (id: string) => {
  return axios.delete(`/category/${id}`);
};
