import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { AuthReducer, MerchantReducer } from "../@core/slice";

const reducers = combineReducers({
  auth: AuthReducer,
  merchant: MerchantReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "merchant"],
};

export type RootState = ReturnType<typeof reducers>;

export const rootReducer = persistReducer(persistConfig, reducers);

export type RootStore = ReturnType<typeof reducers>;
