import { Fragment, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useListProducts } from "../../@core/hooks";
import { useCurrentMerchantSelector } from "../../@core/slice";
import { FilterHeader, NavBar, NoData, Table } from "../../components";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

//Icons
import { AiOutlineEye } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { ConfirmModal } from "../../components/confirm-modal/confirm-modal.component";
import { useDeleteProduct } from "../../@core/hooks/mutation/product/use-delete-employee.hook";
import { Translation } from "../../@core/models/translation/translation";
import {
  getStatusClassName,
  getStatusTranslation,
} from "../orders/statusHelper";

type Product = {
  _id: string;
  name: string;
  sku: string;
  category: {
    name: string;
  };
  status: string;
  translation?: Translation;
};

function Products() {
  const navigate = useNavigate();
  const { merchant } = useCurrentMerchantSelector();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  const {
    data: products,
    isLoading: isProductsLoading,
    refetch: RefetchProductList,
    isRefetching,
  } = useListProducts({
    storeId: merchant?._id ?? "",
    limit: 10,
    page,
  });
  const { mutate: deleteProduct, isLoading: isDeleting } = useDeleteProduct();
  useEffect(() => {
    RefetchProductList();
  }, [RefetchProductList, page]);
  const openConfirmDelete = (selectedItem: any) => {
    setShowConfirmModal(true);
    setSelectedItem(selectedItem);
  };
  const onDelete = (id: string) => {
    deleteProduct(
      {
        id,
        storeId: merchant?._id,
      },
      {
        onSuccess: () => {
          toast.success(t("success.successfullyDelated"));
          queryClient.invalidateQueries("products");
          setSelectedItem(null);
          setShowConfirmModal(false);
        },
        onError() {
          toast.error(t("errors.faildDelated"));
          setShowConfirmModal(false);
        },
      }
    );
  };
  const columns = useMemo(
    () => [
      {
        title: t("product.name"),
        selector: (row: Product) => (
          <>{row?.name}</>
        ),
      },
      {
        title: t("product.sku"),
        selector: (row: Product) => row.sku,
      },
      {
        title: t("product.category"),
        selector: (row: Product) => row.category.name,
      },
      {
        title: t("product.status"),
        selector: (row: Product) => (
          <div className={isRTL ? "divstatusRTl" : ""}>
            <span className={getStatusClassName(row.status, isRTL)}>
              {getStatusTranslation(row.status, t)}
            </span>
          </div>
        ),
      },
      {
        title: t("product.action"),
        selector: (row: Product) => (
          <div className={isRTL ? "flexActionsRtl" : "flexActions"}>
            <Link to={`${row._id}`}>
              <AiOutlineEye size={20} />
            </Link>
            <BiTrash
              size={20}
              onClick={() => {
                openConfirmDelete(row as any);
              }}
            />
          </div>
        ),
      },
    ],
    [isRTL, t]
  );
  const EmptyData = (
    <NoData
      title={t("app.titleNoDataProduct")}
      description={t("app.DescripNoDataProduct")}
      buttonText={t("app.AddProduct")}
      buttonClickHandler={() =>
        navigate(
          `/products/create/${
            merchant?.type === "Food" ? "food-product" : "stock"
          }`
        )
      }
    />
  );
  if (isProductsLoading || isRefetching)
    return (
      <Fragment>
        <NavBar title={t("menuNavigation.products")} />
        <FilterHeader
          hasSearch={false}
          hasStatus={false}
          hasAdd
          iconTitle={t("product.addProduct")}
        />
        <div className="tableContainer" id="tableContainer">
          <Table columns={columns} data={[]}>
            <LoadingSpinner />
          </Table>
        </div>
      </Fragment>
    );

  return (
    <div>
      <NavBar title={t("menuNavigation.products")} />
      <FilterHeader
        hasSearch={false}
        hasStatus={false}
        hasAdd
        iconTitle={t("product.addProduct")}
        onAdd={() =>
          navigate(
            `/products/create/${
              merchant?.type === "Food" ? "food-product" : "stock"
            }`
          )
        }
      />
      <div>
        {Array.isArray(products?.data) && products?.data.length === 0 ? (
          EmptyData
        ) : (
          <div className="tableContainer" id="tableContainer">
            <Table
              columns={columns}
              data={products?.data ?? []}
              handlePageChange={(selectedItem) =>
                setPage(selectedItem.selected)
              }
              pageCount={products?.numberOfPages ?? 0}
              initialPage={page}
            />
          </div>
        )}
      </div>
      <ConfirmModal
        title={t("product.DeleteProduct")}
        description={t("product.DescriptionDeleteProduct")}
        isOpen={showConfirmModal}
        handleConfirm={() => onDelete(selectedItem._id)}
        handleClose={() => setShowConfirmModal(false)}
        isLoading={isDeleting}
      />
    </div>
  );
}

export default Products;
