import { NavLink, Outlet } from "react-router-dom";

//Styles
import "./style.scss";

//Icons
import { HiOutlineInbox } from "react-icons/hi";
import { IoPersonOutline } from "react-icons/io5";
import { MdColorLens, MdOutlineLocalOffer } from "react-icons/md";
import { TbDiscount2, TbRulerMeasure, TbSubtask } from "react-icons/tb";
import { VscSettings } from "react-icons/vsc";
import { useTranslation } from "react-i18next";
import { AiFillTags } from "react-icons/ai";
import { BiAnalyse } from "react-icons/bi";
import { useState } from "react";
import { useCurrentMerchantSelector } from "../../@core/slice";

function MenuBar() {
  const { t } = useTranslation();
  const [isPreferencesOpen, setIsPreferencesOpen] = useState(false);
  const { merchant } = useCurrentMerchantSelector();
  const togglePreferences = () => {
    setIsPreferencesOpen(!isPreferencesOpen);
  };
  return (
    <>
      <div className="menubarContainer">
        <div>
          <img src="/logo.png" alt="logo" />
          <span>BASE</span>
        </div>

        <div className="menuContainer">
          <NavLink
            aria-activedescendant="active"
            className="menuItem"
            to="/products"
          >
            <MdOutlineLocalOffer size={20} />
            <span>{t("menuNavigation.products")}</span>
          </NavLink>

          <NavLink
            aria-activedescendant="active"
            className="menuItem"
            to="/orders"
          >
            <HiOutlineInbox size={20} />
            <span>{t("menuNavigation.orders")}</span>
          </NavLink>

          <NavLink
            aria-activedescendant="active"
            className="menuItem"
            to="/employees"
          >
            <IoPersonOutline size={20} />
            <span>{t("menuNavigation.employees")}</span>
          </NavLink>

          <NavLink
            aria-activedescendant="active"
            className="menuItem"
            to="/discount"
          >
            <TbDiscount2 size={20} />
            <span>{t("menuNavigation.discounts")}</span>
          </NavLink>

          <div
            className="menuItem"
            onClick={togglePreferences}
            style={{ cursor: "pointer" }}
          >
            <VscSettings size={20} />
            <span>{t("menuNavigation.prefrences")}</span>
          </div>
          {isPreferencesOpen && (
            <div className="submenuContainer">
              <NavLink
                aria-activedescendant="active"
                className="menuItem"
                to="/"
              >
                <BiAnalyse size={20} />
                <span>{t("menuNavigation.store")}</span>
              </NavLink>
              <NavLink className="menuItem" to="/sub-categories">
                <TbSubtask size={20} />
                <span>{t("menuNavigation.subcategory")}</span>
              </NavLink>

              <NavLink className="menuItem" to="/tags">
                <AiFillTags size={20} />
                <span>{t("menuNavigation.tags")}</span>
              </NavLink>
              {merchant?.type !== "Food" && (
                <>
                  <NavLink className="menuItem" to="/sizes">
                    <TbRulerMeasure size={20} />
                    <span>{t("menuNavigation.sizes")}</span>
                  </NavLink>

                  <NavLink className="menuItem" to="/colors">
                    <MdColorLens size={20} />
                    <span>{t("menuNavigation.colors")}</span>
                  </NavLink>
                </>
              )}
            </div>
          )}

          {/* <NavLink
						className='menuItem'
						to='/sub-categories'>
						<TbSubtask size={20} />
						<span>
							{t(
								'menuNavigation.subcategory'
							)}
						</span>
					</NavLink>

					<NavLink
						className='menuItem'
						to='/tags'>
						<AiFillTags size={20} />
						<span>
							{t('menuNavigation.tags')}
						</span>
					</NavLink>

					<NavLink
						className='menuItem'
						to='/sizes'>
						<TbRulerMeasure size={20} />
						<span>
							{t('menuNavigation.sizes')}
						</span>
					</NavLink>

					<NavLink
						className='menuItem'
						to='/colors'>
						<MdColorLens size={20} />
						<span>
							{t('menuNavigation.colors')}
						</span>
					</NavLink> */}
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default MenuBar;
