import { useState, useEffect } from "react";
import { InputBox } from "../../components";

//styles
import "./style.scss";
import { t } from "i18next";
import { FormControl, FormHelperText, IconButton, InputAdornment, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useResetPassword } from "../../@core/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";

//icons

type ResetPasswordFormObj = {
  password: string;
  confirmPassword: string;
};

function ResetPassword() {
  const { token } = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const { mutate: resetPassword } = useResetPassword();
  const navigate = useNavigate();

  const onSubmit = (values: any) => {
    resetPassword(
      { password: values.password, confirmPassword: values.confirmPassword, token: token },
      {
        onSuccess: () => {
          toast.success(`${t("auth.passwordReset")}`);
          navigate("/reset-confirmation");
        },
        onError: () => {
          toast.error(t("auth.failedResetPassword"));
        },
      }
    );
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    watch,
  } = useForm<ResetPasswordFormObj>({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const passwordValue = watch("password");
  const confirmPasswordValue = watch("confirmPassword");

  useEffect(() => {
    if (confirmPasswordValue && passwordValue !== confirmPasswordValue) {
      setError("confirmPassword", {
        type: "validate",
        message: t("auth.PasswordsDoNotMatch"),
      });
    } else {
      clearErrors("confirmPassword");
    }
  }, [passwordValue, confirmPasswordValue, setError, clearErrors, t]);

  return (
    <div className="resetPasswordContainer">
      <div className="container">
        <div className="logo">
          <img src="/logoDark.png" alt="logo" />
          <span>BASE</span>
        </div>
        <div>
          <h3>{t("auth.ResetPassword")}</h3>
        </div>
        <div className="formContainer">
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth>
              <Controller
                name="password"
                control={control}
                rules={{
                  pattern: {
                    value: /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,64})$/,
                    message: t("emp.patternPasswored"),
                  },
                  required: {
                    value: true,
                    message: t("emp.PasswordRequired"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    helperText={errors.password ? errors.password.message : "Password must be at least one lowercase letter, one uppercase letter, one digit, one special character, and is at least 8 characters long"}
                    size="small"
                    label={t("auth.EnterNewPassword")}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            size="small"
                            onClick={toggleShowPassword}
                            sx={{
                              backgroundColor: "transparent !important",
                              color: "#000000 !important",
                            }}
                          >
                            {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" sx={{ backgroundColor: "transparent" }} />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder=""
                    error={!!errors.password}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("emp.PasswordRequired"),
                  },
                  validate: (value) =>
                    value === passwordValue || t("auth.PasswordsDoNotMatch"),
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    label={t("auth.ConfirmPassword")}
                    type={showConfirmPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm password visibility"
                            onClick={toggleShowConfirmPassword}
                            edge="end"
                            size="small"
                            sx={{
                              backgroundColor: "transparent !important",
                              color: "#000000 !important",
                            }}
                          >
                            {showConfirmPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder=""
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword ? errors.confirmPassword.message : ""}
                  />
                )}
              />
            </FormControl>

            <button>{t("auth.Reset")}</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
