import { UpdateEmployee } from "../../../api";
import { EmployeeResponse } from "../../../models";
import { useCustomMutation } from "../use-mutation.hook";

export const useUpdateEmployee = () => {
  return useCustomMutation<any, EmployeeResponse>({
    url: "store-employee",
    api: UpdateEmployee,
  });
};
