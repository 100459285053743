import instance from "../../../utils/axios.util";
import { RegisterDto } from "../../models";

export async function Register(body: RegisterDto) {
  try {
    const res = await instance.post("auth/register", { ...body });

    return res.data;
  } catch (error) {
    throw error;
  }
}
