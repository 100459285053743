import instance from "../../../utils/axios.util";
import { updateStoreDto } from "../../models";

interface IupdateStoreDto {
  id: string;
  body: Partial<updateStoreDto>;
}

export async function UpdateStore({ id,body }: IupdateStoreDto) {
  try {
    const res = await instance.patch(`store/${id}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}
