import { useMutation } from 'react-query';
import {
	addTag,
	deleteTag,
	editTag,
} from '../../../api/tags/tags.api';

export const useAddTags = () => {
	return useMutation(addTag);
};

export const useDeleteTags = () => {
	return useMutation(deleteTag);
};

export const useEditTags = () => {
	return useMutation(editTag);
};
