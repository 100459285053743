import React from "react";
import { GrFormClose } from "react-icons/gr";

//Styles
import "./style.scss";

type FloatingFormProps = {
  visible: boolean;
  setVisible: () => void;
  title: string;
  body: any;
  btnName?: string;
  onClick?: () => void;
};

function FloatingForm({
  visible,
  setVisible,
  title,
  body,
  btnName,
  onClick,
}: FloatingFormProps) {
  return visible ? (
    <div className="floatingFormContainer">
      <div className="container">
        <div className="header">
          <div className="title">{title}</div>
          <button className="floatingFormCloseButton" onClick={setVisible}>
            <GrFormClose size={30} />
          </button>
        </div>

        {body}
      </div>
    </div>
  ) : (
    <></>
  );
}

export default FloatingForm;
