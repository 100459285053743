import React from 'react';
import './sub-nav-bar.component.scss';
import { Grid } from '@mui/material';

interface Props extends React.HTMLProps<HTMLDivElement> {}

export const SubNavBar: React.FC<Props> = ({
	children,
}) => {
	return (
		<div className='sub-nav-bar'>
			<Grid container justifyContent='flex-end'>
				<Grid item>{children}</Grid>
			</Grid>
		</div>
	);
};
