import { Navigate } from "react-router-dom";

type ProtectedRouteProps = {
  children: JSX.Element;
  user: boolean;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, user }) => {
  if (!user) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
