import React, { useEffect } from "react";
import { TextInput, SelectInput } from "../../components/custom-inputs";
import { useForm } from "react-hook-form";
import { Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSubcategories } from "../../@core/hooks";
import { useCurrentMerchantSelector } from "../../@core/slice";
import { useTranslation } from "react-i18next";
import "./style.scss";

interface Props extends React.HTMLProps<HTMLDivElement> {
  selectedItem: any;
  isSubmitting: boolean;
  closeModal: () => void;
  submitFormHandler: (data: any) => void;
}

export const AddTagsForm: React.FC<Props> = ({
  closeModal,
  submitFormHandler,
  isSubmitting,
  selectedItem,
}) => {
  const { merchant } = useCurrentMerchantSelector();
  const { t } = useTranslation();
  const {
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      category: merchant?.category?._id,
      subCategory: null,
    },
  });

  const { data: subCategories } = useSubcategories(merchant?.category?._id, {
    paginate: false,
  });

  const subCategoryOptions = subCategories?.data?.map((subCategory) => ({
    label: subCategory.name,
    value: subCategory._id,
  }));

  useEffect(() => {
    if (selectedItem) {
      setValue("name", selectedItem.name || "");
      setValue("subCategory", selectedItem.subCategory._id);
    }
  }, [selectedItem, setValue]);

  return (
    <form onSubmit={handleSubmit(submitFormHandler)}>
      <Box sx={{ marginBottom: "1rem" }}>
        <TextInput
          control={control}
          inputId="tag-name"
          name="name"
          label={t("tag.TagName")}
          rules={{
            required: true,
          }}
          error={errors.name}
        />
      </Box>

      <Box sx={{ marginBottom: "1rem" }}>
        <SelectInput
          inputId="subCategory"
          name={"subCategory"}
          label={t("tag.ChooseSubcategory")}
          options={subCategoryOptions}
          rules={{
            required: true,
          }}
          error={errors.subCategory}
          control={control}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "12px",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <LoadingButton
          type="submit"
          className="buttonHandleSubmit"
          size="large"
          loading={isSubmitting}
          disabled={isSubmitting}
          variant="contained"
        >
          {selectedItem ? t("tag.EditTag") : t("tag.AddTag")}
        </LoadingButton>
        <Button
          type="button"
          className="formButton"
          variant="outlined"
          size="large"
          disabled={isSubmitting}
          onClick={() => closeModal()}
        >
          {t("product.Cancel")}
        </Button>
      </Box>
    </form>
  );
};
