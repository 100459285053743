import { CreateStore } from "../../../api";
import { Store } from "../../../models";
import { useCustomMutation } from "../use-mutation.hook";

export const useCreateStore = () => {
  return useCustomMutation<Store, Store>({
    url: "/store",
    api: CreateStore,
  });
};
