import React from "react";
import SelectBox from "../SelectBox";

//styles
import "./style.scss";

//Icons
import { BiSearch } from "react-icons/bi";
import { HiPlus } from "react-icons/hi";
import { useTranslation } from "react-i18next";

type FilterHeaderProps = {
  hasSearch?: boolean;
  searchPlaceHolder?: string;
  searchValue?: string;
  searchName?: string;
  searchOnChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;

  hasStatus?: boolean;
  selectorPlaceHolder?: string;
  selectorValue?: string;
  selectorName?: string;
  selectorOnChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  selectorData?: () => any[];

  selectorPlaceHolderpluse?: string;
  selectorValuepluse?: string;
  selectorNamepluse?: string;
  selectorOnChangepluse?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  selectorDataplus?: () => any[];
  hasStatuspluse?: boolean;

  hasAdd?: boolean;
  iconTitle?: string;
  onAdd?: () => void;
};

function FilterHeader({
  hasSearch = true,
  searchPlaceHolder = "Search for product, vendor, category",
  searchValue = "",
  searchName = "",
  searchOnChange = () => {},

  hasStatus = true,
  selectorPlaceHolder = "",
  selectorValue = "",
  selectorName = "",
  selectorOnChange = () => {},
  selectorData = () => [],

  hasStatuspluse = false,
  selectorPlaceHolderpluse = "",
  selectorValuepluse = "",
  selectorNamepluse = "",
  selectorOnChangepluse = () => {},
  selectorDataplus = () => [],

  hasAdd = true,
  iconTitle = "",
  onAdd = () => {},
}: FilterHeaderProps) {
  const { t } = useTranslation();
  return (
    <div className="filterHeaderContainer">
      {hasSearch && (
        <div className="search">
          <BiSearch />
          <input
            type="text"
            placeholder={t(`app.${searchPlaceHolder}`)}
            value={searchValue}
            name={searchName}
            onChange={searchOnChange}
          />
        </div>
      )}
      {hasStatuspluse && (
        <>
          <SelectBox
            getData={() => selectorDataplus}
            onChange={selectorOnChangepluse}
            value={selectorValuepluse}
            name={selectorNamepluse}
            placeHolder={t(`app.${selectorPlaceHolderpluse}`)}
          />
        </>
      )}
      {hasStatus && (
        <SelectBox
          getData={() => selectorData}
          onChange={selectorOnChange}
          value={selectorValue}
          name={selectorName}
          placeHolder={selectorPlaceHolder?t(`app.${selectorPlaceHolder}`):""}
        />
      )}
      {hasAdd && (
        <button
          style={{ marginBottom: "10px" }}
          className="addContainer"
          onClick={onAdd}
          aria-label={iconTitle}
        >
          {iconTitle} <HiPlus size={20} />
        </button>
      )}
    </div>
  );
}

export default FilterHeader;
