import { GetDiscountDto } from "../../../models";
import { useCustomQuery } from "../use-query.hook";

interface Props {
  storeId: string;
  page?: number;
  limit?: number;
  paginated?: boolean;
  search?: string;
  isActive?: boolean | undefined;
  type?: string | undefined;
}

export const useListDiscount = ({
  storeId,
  page = 1,
  limit = 10,
  paginated = true,
  search = "",
  isActive,
  type,
}: Props) => {
  return useCustomQuery<GetDiscountDto>({
    url: `${storeId}/discount`,
    dependedKey: `discount/store/${storeId}`,
    page,
    limit,
    paginated,
    search,
    isActive,
    type,
  });
};
