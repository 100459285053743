import instance from "../../../utils/axios.util";
import { LoginDto } from "../../models";

export async function Login(body: LoginDto) {
  try {
    const res = await instance.post("auth/login", { ...body });
    return res.data;
  } catch (error) {
    throw error;
  }
}
