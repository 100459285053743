import instance from "../../../utils/axios.util";
import { ForgotPasswordDto } from "../../models";

export async function ForgotPassword(body: ForgotPasswordDto) {
  try {
    const res = await instance.post("/request-forget-password", { ...body });
    return res.data;
  } catch (error) {
    throw error;
  }
}
