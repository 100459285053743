import { PaletteColorOptions, createTheme } from "@mui/material";
import i18n from "../../utils/i18n";

const isRtl = i18n.dir() === "rtl";

declare module "@mui/material/styles" {
  interface CustomPalette {
    basePrimary: PaletteColorOptions;
    apple: PaletteColorOptions;
    steelBlue: PaletteColorOptions;
    violet: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    basePrimary: true;
    apple: true;
    steelBlue: true;
    violet: true;
  }
}

const { palette } = createTheme();

const { augmentColor } = palette;

const createColor = (mainColor: any) =>
  augmentColor({ color: { main: mainColor } });

export const theme = createTheme({
  palette: {
    basePrimary: createColor("#344767"),
    apple: createColor("#5DBA40"),
    steelBlue: createColor("#5C76B7"),
    violet: createColor("#BC00A3"),
  },
});
