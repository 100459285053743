import React, { useEffect, useMemo } from "react";
import { NavBar, Table } from "../../../../components";
import { useParams, useNavigate } from "react-router-dom";

//styles
import "./style.scss";

//Icons
import { CgFileDocument } from "react-icons/cg";
import { FiEdit } from "react-icons/fi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useProduct } from "../../../../@core/hooks";
import { useCurrentMerchantSelector } from "../../../../@core/slice";
import { Category, Color, Product, Size } from "../../../../@core/models";
import { StockProduct } from "../../../../@core/models/product/stock-product/stock-product";
import { Variant } from "../../../../@core/models/product/stock-product/variant";
import { useTranslation } from "react-i18next";

function ViewProduct() {
  const navigation = useNavigate();

  const { t, i18n } = useTranslation();
  const { productId } = useParams();

  const isRTL = i18n.dir() === "rtl";

  const { merchant } = useCurrentMerchantSelector();

  const { data, isLoading, refetch } = useProduct({
    productId: productId as string,
    storeId: merchant!!._id,
  });

  useEffect(() => {
    refetch();
  }, [productId, merchant, refetch]);
  const details = data?.data as Product;

  const columns = useMemo(
    () => [
      {
        title: t("product.details.color"),
        selector: (row: Variant) => (row.color as Color).name,
      },
      {
        title: t("product.details.size"),
        selector: (row: Variant) => (row.size as Size).size,
      },
      {
        title: t("product.details.price"),
        selector: (row: Variant) => row.price,
      },
      {
        title: t("product.details.stock"),
        selector: (row: Variant) => row.stock,
      },
    ],
    [t]
  );

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="viewProductContainer">
      <NavBar
        title={t("product.details.title")}
        hasBack={true}
        status={details?.status}
      />
      <div className="viewProductAction">
        <div className="routes">
          <span onClick={() => navigation("/products")}>
            {t("menuNavigation.products")}
          </span>

          {isRTL ? (
            <IoIosArrowBack fontWeight={900} size={20} />
          ) : (
            <IoIosArrowForward fontWeight={900} size={20} />
          )}
          <span style={{ color: "sandybrown" }}>{details?.name}</span>
        </div>
        <div className="editButton">
          <button
            onClick={() =>
              navigation(
                `/products/edit/${details._id?.toString()}/${
                  merchant?.type !== "Food" ? "stock" : "non-stock"
                }`
              )
            }
          >
            <FiEdit size={15} />
            {t("product.details.editProduct")}
          </button>
        </div>
      </div>
      <div className="content">
        <div>
          <div className="header">
            <CgFileDocument />
            {t("product.details.title")}
          </div>
          <div className="container">
            <div className="productImages">
              <div>
                <div> {t("product.details.productImage")} </div>
                <img src={details?.mainImage} alt="mainImage" />
              </div>
              <div>
                {(details as StockProduct).variants?.length ? (
                  <div>{t("product.details.variantImages")}</div>
                ) : null}
                <div>
                  <>
                    {(details as StockProduct).variants
                      ? (details as StockProduct).variants?.map((variant) =>
                          variant?.images?.map((image, index) => (
                            <img src={image} alt="mainImage" key={index} />
                          ))
                        )
                      : null}
                  </>
                </div>
              </div>
            </div>
            <div className="productInfo">
              <div>
                <div className="title">{t("product.details.productSku")}</div>
                <div className="data">{details?.sku}</div>
              </div>
              <div>
                <div className="title">{t("product.details.productName")}</div>
                <div className="data">{details?.name}</div>
              </div>
              <div>
                <div className="title">
                  {t("product.details.productCategory")}
                </div>
                <div className="data">
                  {(details?.category as Category)?.name}
                </div>
              </div>
              <div>
                <div className="title">
                  {t("product.details.productSubcategory")}
                </div>
                <div className="data">
                  {details?.subCategory
                    ? (details?.subCategory as Category)?.name
                    : "N/A"}
                </div>
              </div>
            </div>

            <hr />
            <div>
              <div className="title">
                {t("product.details.productDiscount")}
              </div>
              <div className="data">
                {details?.discountPrice
                  ? details.discountPrice + " KWD"
                  : "N/A"}
              </div>
            </div>
            <hr />
            <div>
              <div className="title">
                {t("product.details.productDescription")}
              </div>
              <div className="data">{details?.description}</div>
            </div>
          </div>
        </div>

        <div>
          <div>
            <div className="header">
              <CgFileDocument />
              {t("product.details.productPrice")}
            </div>
            <div className="container">
              <div className="title">{t("product.details.productPrice")}</div>
              <div className="data">
                {details?.price} {t("currency.kwd")}
              </div>
            </div>
          </div>
          <div>
            <div className="header">
              <CgFileDocument />
              {t("product.details.productTags")}
            </div>
            {details?.tags?.length ? (
              <div className="container">
                {details?.tags?.map((tag, key) => (
                  <Tag name={tag.name} key={key} />
                ))}
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <div>
            <div className="header">
              <CgFileDocument />
              {t("product.details.stock")}
            </div>
            <div className="container">
              <div className="title">{t("product.details.stock")}</div>
              <div className="data">{details?.stock}</div>
            </div>
          </div>
        </div>

        <div className="varientsContainer">
          <div className="header">
            <CgFileDocument />
            {t("product.details.variants")}
          </div>
          <div className="tableContainer" id="tableContainer">
            <Table
              columns={columns}
              data={(details as StockProduct)?.variants as Variant[]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewProduct;

type TagProps = {
  name: string;
};

const Tag = ({ name }: TagProps) => (
  <div className="tagContainer">
    <span>{name}</span>
  </div>
);
