import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Employee, ICreateEmployeeDto } from "../../../@core/models";
import { useCurrentMerchantSelector } from "../../../@core/slice";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../components/custom-inputs";
import { useUpdateEmployee } from "../../../@core/hooks/mutation/employee/use-update-employee";
import { CountryCodeInput } from "../../signup/components/countryCodeInput.common";
import { PhotoCamera } from "@mui/icons-material";
import { useState } from "react";
// import { useEmployee } from "../../../@core/hooks/query/employee";

interface Props extends React.HTMLProps<HTMLDivElement> {
  closeModal: () => void;
  selectedEmployee?: Employee;
}

export const EditEmployeeForm: React.FC<Props> = ({
  closeModal,
  selectedEmployee,
}) => {
  const { merchant } = useCurrentMerchantSelector();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [avatarChanged, setAvatarChanged] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ICreateEmployeeDto>({
    defaultValues: {
      name: selectedEmployee?.name,
      email: selectedEmployee?.email,
      countryCode: selectedEmployee?.countryCode,
      phoneNumber: selectedEmployee?.phoneNumber,
      // password: "",
      gender: selectedEmployee?.gender,
      dateOfBirth: selectedEmployee?.gender,
      job: selectedEmployee?.job || "",
      avatar: selectedEmployee?.avatar || "",
    },
  });

  const { mutate: editEmployee } = useUpdateEmployee();

const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  if (e.target.files && e.target.files[0]) {
    setValue("avatar", e.target.files[0], { shouldValidate: true });
    setAvatarChanged(true);
  }
};


  const onSubmit = (values: ICreateEmployeeDto) => {
    if (values.gender === "") delete values.gender;
    if (values.dateOfBirth === "") delete values.dateOfBirth;

    const body = new FormData();
    body.append("email", values.email ?? "");
    body.append("name", values.name ?? "");
    body.append("job", values.job ?? "");
    body.append("countryCode", values.countryCode ?? "");
    body.append("phoneNumber", values.phoneNumber ?? "");
  if (avatarChanged && values.avatar instanceof File) {
    body.append("avatar", values.avatar);
  }

    if (values.gender) {
      body.append("gender", values.gender);
    }
    if (values.dateOfBirth) {
      body.append("dateOfBirth", values.dateOfBirth);
    }
    editEmployee(
      {
        id: selectedEmployee?._id,
        storeId: merchant?._id,
        body,
      },
      {
        onSuccess: (data) => {
          toast.success(t("emp.sucessUpdate"));
          queryClient.invalidateQueries({
            queryKey: [`employee/store/${merchant?._id}`, []],
          });
          queryClient.invalidateQueries({
            queryKey: [`employees`],
          });
          closeModal();
        },
        onError: () => {
          toast.error(t("emp.faildUpdate"));
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={12}>
            {/* Employee avatar */}
            <Box
              width={"100%"}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <div>
                <FormControl fullWidth>
                  <Box display="flex" alignItems="center">
                    <Controller
                      name="avatar"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Avatar
                            src={
                              typeof field.value === "string"
                                ? field.value
                                : URL.createObjectURL(field.value)
                            }
                            alt="avatar"
                            sx={{ width: 110, height: 110 }}
                          />
                          <IconButton
                            aria-label="upload picture"
                            component="label"
                            sx={{
                              position: "absolute",
                              bottom: "-3%",
                              right: "-12%",
                            }}
                          >
                            <input
                              type="file"
                              hidden
                              accept="image/*"
                              onChange={handleAvatarChange}
                            />
                            <PhotoCamera />
                          </IconButton>
                        </>
                      )}
                    />
                  </Box>
                </FormControl>
                <FormHelperText error={!!errors.avatar}>
                  {errors.avatar
                    ? (errors.avatar.message as React.ReactNode)
                    : null}
                </FormHelperText>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Employee Name */}
            <TextInput
              name="name"
              inputId="name"
              type="string"
              label={t("emp.EmployeeName")}
              rules={{
                required: {
                  value: true,
                  message: t("emp.EmployeeNameRequired"),
                },
              }}
              control={control}
              error={errors.name}
              inputProps="text"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Employee Email */}
            <TextInput
              name="email"
              inputId="email"
              type="string"
              label={t("emp.EmployeeEmail")}
              rules={{
                required: {
                  value: true,
                  message: t("emp.EmployeeEmailRequired"),
                },
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: t("emp.Pattern"),
                },
              }}
              control={control}
              error={errors.email}
              inputProps="email"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Employee Job */}
            <TextInput
              name="job"
              inputId="job"
              type="string"
              label={t("emp.EmployeeJob")}
              rules={{
                required: {
                  value: true,
                  message: t("emp.EmployeeJobRequired"),
                },
              }}
              control={control}
              error={errors.job}
              inputProps="text"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel
              sx={{ textAlign: "initial !important" }}
              className="label"
              htmlFor={"phoneNumber"}
            >
              {t("emp.phoneNumber")}
            </InputLabel>
            <FormControl fullWidth>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                }}
              >
                <CountryCodeInput
                  id="countryCode"
                  label=""
                  errors={errors}
                  control={control}
                />
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t("emp.PhoneRequired"),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      className="textField"
                      sx={{
                        width: "90% !important",
                        gap: 1,
                      }}
                      {...field}
                      inputProps={{
                        inputMode: "text",
                      }}
                      type="string"
                      placeholder=""
                      error={!!errors.phoneNumber}
                    />
                  )}
                />
              </Box>

              <FormHelperText error={!!errors.phoneNumber}>
                {errors.phoneNumber?.message}
              </FormHelperText>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <TextInput
              name="password"
              inputId="password"
              type="password"
              label={t("emp.Password")}
              rules={{
                required: {
                  value: true,
                  message: t("emp.PasswordRequired"),
                },
                pattern: {
                  // regex to validate password with at least one lowercase letter, one uppercase letter, one digit, one special character, and is at least 8 characters long
                  value: /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,64})$/,
                  message: t("emp.patternPasswored"),
                },
              }}
              control={control}
              error={errors.password}
              inputProps="text"
            />
          </Grid> */}

          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                sx={{ mx: 3 }}
                type="button"
                className="formButton"
                size="large"
                variant="outlined"
                color="error"
                onClick={closeModal}
              >
                {t("emp.Cancel")}
              </Button>

              <Button
                type="submit"
                className="buttonHandleSubmit"
                size="large"
                variant="contained"
                color="basePrimary"
              >
                {t("emp.EditEmployee")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
