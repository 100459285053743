import { useMutation } from 'react-query'
import { deleteSubCategory, editSubCategory, postSubCategory } from '../../../api/categories/categories.api';

export const usePostSubCategory = () => {
	return useMutation(postSubCategory);
}

export const useDeleteSubCategory = () => {
	return useMutation(deleteSubCategory);
}
export const useEditSubCategory = () => {
  return useMutation(editSubCategory);
};
