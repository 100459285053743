import axios from '../../../utils/axios.util';
import { GetPageParams } from '../../models/page/page.model';

interface AddSizeParams {
	size: string;
	category: string;
	subCategory: string;
}
export interface Category {
  _id: string;
  name: string;
}

export interface SubCategory {
  _id: string;
  name: string;
}
export interface Size {
  _id: string;
  category: Category;
  subCategory: SubCategory;
  size: string;
}
export interface ApiResponse {
  success: boolean;
  data: Size[];
  currentPage: number;
  numberOfPages: number;
  numberOfRecords: number;
}

export const getAllSizes = async (
  params: GetPageParams
): Promise<ApiResponse> => {
  const { skip, limit } = params;
  const response = await axios.get<ApiResponse>("/size", {
    params: {
      skip: skip ?? undefined, 
      limit: limit ?? undefined, 
      paginate: !!(skip !== undefined && limit !== undefined), 
    },
  });
  return response.data;
};
export const addSize = ({
	size,
	category,
	subCategory,
}: AddSizeParams) => {
	return axios.post('/size', {
		size,
		category,
		subCategory,
	});
};

export const editSize = ({
	id,
	data,
}: {
	id: string;
	data: Partial<AddSizeParams>;
}) => {
	return axios.patch(`/size/${id}`, data);
};

export const deleteSize = (id: string) => {
	return axios.delete(`/size/${id}`);
};
