import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormSetValue,
} from "react-hook-form";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";

import "react-phone-number-input/style.css";
import { Box, FormHelperText, Input, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.css";
import { WidthFull } from "@mui/icons-material";
interface CountryCodeInputProps<TFieldValues extends FieldValues> {
  id: string;
  label: string;
  errors: FieldErrors<TFieldValues>;
  setValue?: UseFormSetValue<TFieldValues>;
  control?: Control<TFieldValues>;
}

export const CountryCodeInput = <TFieldValues extends FieldValues>({
  id,
  label,
  errors,
  control,
}: CountryCodeInputProps<TFieldValues>) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column">
      <InputLabel className="label" htmlFor={"countryCode"} sx={{
        border: "none"
      }}>
        {label}
      </InputLabel>
      <PhoneInputWithCountry
        className="countryCode"
        international
        placeholder={t("Enter-The-country-Code")}
        defaultCountry="KW"
        defaultValue={"+965"}
        name="countryCode"
        control={control}
        // rules={{
        //   required: {
        //     value: true,
        //     message: t("Country-Code-is-required"),
        //   },
        // }}
        style={{
          // border: "2px solid #d6ddeb",
          // borderRadius: "4px",
          width: "90px",
          // padding: "18px 5px",
          // height: "1.4375em",
          // margin: 0,
          padding: "10px 5px",
          outline: "none !important",
          border: "1px solid #c5c6cc",
          borderRadius: '4px',
        }} />
      {errors[id] && (
        <FormHelperText error>{errors[id]?.message as string}</FormHelperText>
      )}
    </Box>
  );
};


