// Note: SVG Icons

interface StatusIconProps {
  stroke?: string;
  onClick?: () => void;
}

export const STATUS_ICON_STROKE = {
  COLORED: "#16268D",
  COLORED_LINE: "#64C6E4",
  GRAY: "#969998",
};

export const StatusVectorSVG = ({ stroke, onClick }: StatusIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    cursor={"pointer"}
    fill="none"
    onClick={onClick}
  >
    <circle
      cx="9"
      cy="9"
      r="7"
      stroke={stroke || STATUS_ICON_STROKE.COLORED}
      stroke-width="4"
    />
  </svg>
);

export const LineVectorSVG = ({ stroke }: StatusIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="244"
    viewBox="0 0 190 5"
    fill="none"
  >
    <path
      d="M2 2.5H191.5"
      stroke={stroke || STATUS_ICON_STROKE.COLORED}
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
