import instance from "../../../utils/axios.util";
import { Store } from "../../models";

export async function CreateStore(body: Store) {
  try {
    const res = await instance.post("store", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
}
