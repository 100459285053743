import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { GetStoreDto } from "../models";

interface Merchant {
  merchant: GetStoreDto | null;
}

const initialState: Merchant = {
  merchant: null,
};

const merchantSlice = createSlice({
  name: "merchantSlice",
  initialState,
  reducers: {
    setStore(state, { payload }) {
      state.merchant = payload?.data;
      return state;
    },
    updateStore(state, action: PayloadAction<{ data: Partial<GetStoreDto> }>) {
      if (state.merchant) {
        state.merchant = { ...state.merchant, ...action.payload.data };
      }
    },
  },
});

export const useCurrentMerchantSelector = () =>
  useSelector((state: RootState) => state.merchant);

export default merchantSlice.reducer;

export const { setStore, updateStore } = merchantSlice.actions;
