import React from 'react';
import './no-data.component.scss';
import { Box, Button } from '@mui/material';

interface Props extends React.HTMLProps<HTMLDivElement> {
	title: string;
	description: string;
	buttonText: string;
	buttonClickHandler?: () => void;
}

export const NoData: React.FC<Props> = ({
	title,
	description,
	buttonText,
	buttonClickHandler,
}) => {
  return (
    <Box className="tableContainer">
      <div className="no-data">
        <div className="no-data__graphic">
          <img src="/images/empty-state.png" alt="no data" />
        </div>
        <h3 className="no-data__text">{title}</h3>
        <p className="no-data__subtext">{description}</p>
        <div className="no-data__button">
          <Button
            className="buttonHandleSubmit"
            variant="contained"
            size="large"
            onClick={buttonClickHandler}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </Box>
  );
};
