import { Fragment, useEffect, useMemo, useState } from "react";
import { FilterHeader, NavBar, NoData, Table } from "../../components";

//Icons
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useListOrders } from "../../@core/hooks/";
import { Order, User } from "../../@core/models";
import { useCurrentMerchantSelector } from "../../@core/slice";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useTranslation } from "react-i18next";
import { getStatusClassName, getStatusTranslation } from "./statusHelper";

function Orders() {
  const navigation = useNavigate();
  const { t, i18n } = useTranslation();
  const { merchant } = useCurrentMerchantSelector();
  const [page, setPage] = useState(0);
  const isRTL = i18n.dir() === "rtl";
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("0");

  const optionStatus = useMemo(() => {
    return status === "1"
      ? "Pending"
      : status === "2"
      ? "Placed"
      : status === "3"
      ? "Packing"
      : status === "4"
      ? "Shipping"
      : status === "5"
      ? "Delivered"
      : status === "6"
      ? "Cancelled"
      : undefined;
  }, [status]);

  const {
    data: orders,
    isLoading: isOrdersLoading,
    refetch: RefetchOrderList,
    isRefetching,
  } = useListOrders({
    storeId: merchant?._id ?? "",
    limit: 10,
    page,
    search: search,
    status: optionStatus,
  });

  useEffect(() => {
    RefetchOrderList();
  }, [RefetchOrderList, page, search, status]);

  const handlePageChange = (selectedItem: any) => {
    setPage(selectedItem.selected);
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (name === "search") {
      if (value.startsWith("#")) {
        setSearch(value.substring(1));
      } else {
        setSearch(value);
      }
    }
    if (name === "status") setStatus(value);
  };
  const columns = useMemo(
    () => [
      {
        title: t("order.orderNumber"),
        selector: (row: Order) => row.orderRefId,
      },
      {
        title: t("order.customer"),
        selector: (row: Order) => (row.buyer as User)?.name,
      },
      {
        title: t("order.total"),
        selector: (row: Order) => row.totalPrice + " " + t("currency.kwd"),
      },
      {
        title: t("order.date"),
        selector: (row: Order) =>
          new Date(row.createdAt).toLocaleDateString().toLocaleUpperCase(),
      },
      {
        title: t("order.status"),
        selector: (row: Order) => (
          <div className={isRTL ? "divstatusRTl" : ""}>
            <span className={getStatusClassName(row.status, isRTL)}>
              {getStatusTranslation(row.status, t)}
            </span>
          </div>
        ),
      },
      {
        title: t("order.action"),
        selector: (row: Order) => (
          <>
            <AiOutlineEye
              size={20}
              onClick={() => navigation(`/orders/${row._id}`)}
            />
          </>
        ),
      },
    ],
    [isRTL, navigation, t]
  );
  const EmptyData = (
    <NoData
      title={t("app.titleNoDataOrder")}
      description={t("app.DescripNoDataOrder")}
      buttonText={t("app.AddOrder")}
      // buttonClickHandler={openAddModalHandler}
    />
  );
  if (isOrdersLoading || isRefetching)
    return (
      <Fragment>
        <NavBar title={t("menuNavigation.orders")} />
        {/* <FilterHeader
          // Search
          searchName="search"
          searchPlaceHolder={t("app.Search")}
          searchValue={search}
          searchOnChange={handleChange}
        /> */}

        <div className="tableContainer" id="tableContainer">
          <Table columns={columns} data={[]}>
            <LoadingSpinner />
          </Table>
        </div>
      </Fragment>
    );

  return (
    <Fragment>
      <NavBar title={t("menuNavigation.orders")} />
      <FilterHeader
        // Search
        searchName="search"
        searchPlaceHolder={t("Search")}
        searchValue={search}
        searchOnChange={handleChange}
        // Select Box
        selectorName="status"
        selectorValue={status}
        selectorOnChange={handleChange}
        selectorData={() => [
          { _id: 0, name: t("app.Filter by status") },
          { _id: 0, name: t("app.AllStatus") },
          { _id: 1, name: t("status.Pending") },
          { _id: 2, name: t("status.Placed") },
          { _id: 3, name: t("status.Packing") },
          { _id: 4, name: t("status.Shipping") },
          { _id: 5, name: t("status.Delivered") },
          { _id: 6, name: t("status.Cancelled") },
        ]}
      />
      <div>
        {Array.isArray(orders?.data) && orders?.data.length === 0 ? (
          EmptyData
        ) : (
          <div className="tableContainer" id="tableContainer">
            <Table
              columns={columns}
              data={orders?.data ?? []}
              handlePageChange={handlePageChange}
              pageCount={orders?.numberOfPages ?? 0}
              initialPage={page}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default Orders;
