// Convert number to day starting from 1 to 7 (Sunday to Saturday)
import i18next from "i18next";
export const numberToDay = (day: number) => {
  const t = (el: string) => i18next.t(el);
  const days = [
    t("prefrences.sunday"),
    t("prefrences.monday"),
    t("prefrences.tuesday"),
    t("prefrences.wednesday"),
    t("prefrences.thursday"),
    t("prefrences.friday"),
    t("prefrences.saturday"),
  ];
  return days[day];
};
