import { GetStoreDto, StoreFormData } from "../../@core/models";
import dayjs from "dayjs";

export const transformStoreData = (
  data: GetStoreDto,
  EngLang: any
): StoreFormData => {
  return {
    category: data.category._id,
    nameEn: EngLang ? data?.name : data?.translation?.name || "",
    nameAr: EngLang ? data?.translation?.name : data?.name || "",
    descriptionAr: EngLang
      ? data?.translation?.description
      : data?.description || "",
    descriptionEn: EngLang
      ? data?.description
      : data?.translation?.description || "",
    image: data.image,
    workingHours: data.workingHours.map((wh) => ({
      day: wh.day,
      operationTimeStart: dayjs(wh.operationTimeStart, "HH:mm"),
      operationTimeEnd: dayjs(wh.operationTimeEnd, "HH:mm"),
    })),
    longitude: data.location.coordinates[0],
    latitude: data.location.coordinates[1],
  };
};
export const mapDayIndexToDayName = (dayIndex: number, i18n: any) => {
  const days: any[] = [
    {
      en: "sunday",
      ar: "الاحد",
    },
    {
      en: "monday",
      ar: "الاثنين",
    },
    {
      en: "tuesday",
      ar: "الثلاثاء",
    },
    {
      en: "wednesday",
      ar: "الاربعاء",
    },
    {
      en: "thursday",
      ar: "الخميس",
    },
    {
      en: "friday",
      ar: "الجمعة",
    },
    {
      en: "saturday",
      ar: "السبت",
    },
  ];

  return days[dayIndex]?.[i18n.language === "en" ? "en" : "ar"];
};
