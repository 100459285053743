import { Box, Grid, InputLabel, Stack, Button } from "@mui/material";
import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { StoreFormData } from "../../@core/models";
import { TimePickerInput } from "../../components/time-picker/time-picker.component";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import { SelectInput, TextInput } from "../../components/custom-inputs";
import "./style.scss";
import { ImageUploader } from "../../components/StockProduct/imageUploader";
import { mapDayIndexToDayName } from "./storeHelper";
interface Props extends React.HTMLProps<HTMLDivElement> {
  submitHandler: (values: StoreFormData) => void;
  closeModal: () => void;
  categoryOptions?: {
    value: string;
    label: string;
  }[];
}

export const AddStoreFormBody: React.FC<Props> = ({
  submitHandler: onSubmit,
  categoryOptions,
  closeModal,
}) => {
  const { i18n, t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<StoreFormData>({
    defaultValues: {
      category: "",
      nameAr: "",
      nameEn: "",
      descriptionAr: "",
      descriptionEn: "",
      image: undefined,
      workingHours: Array.from({ length: 7 }, (_, index) => ({
        day: index,
        operationTimeStart: undefined,
        operationTimeEnd: undefined,
      })),
      longitude: 47.4818,
      latitude: 29.3117,
      returnPolicyAr: "",
      exchangePolicyAr: "",
      returnPolicyEn: "",
      exchangePolicyEn: "",
    },
  });

  const workingHoursFields = useFieldArray({
    control,
    name: "workingHours",
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            {/* Name Arabic */}
            <TextInput
              inputId="nameAr"
              name="nameAr"
              label={t("store.Arabicname")}
              type="string"
              rules={{
                required: {
                  value: true,
                  message: t("store.ArabicNamerequired"),
                },
              }}
              control={control}
              error={errors.nameAr}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {/* Name English */}
            <TextInput
              inputId="nameEn"
              name="nameEn"
              label={t("store.Englishname")}
              type="string"
              rules={{
                required: {
                  value: true,
                  message: t("store.EnglishNamerequired"),
                },
              }}
              control={control}
              error={errors.nameEn}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {/* Description Arabic */}
            <TextInput
              inputId="descriptionAr"
              name="descriptionAr"
              label={t("store.ArabicDescription")}
              type="string"
              control={control}
              rows={4}
              rules={{
                required: {
                  value: true,
                  message: t("store.ArabicDescriptionRequired"),
                },
              }}
              error={errors.descriptionAr}
              multiline
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {/* Description English */}
            <TextInput
              inputId="descriptionEn"
              name="descriptionEn"
              label={t("store.EnglishDescription")}
              type="string"
              control={control}
              rows={4}
              rules={{
                required: {
                  value: true,
                  message: t("store.EnglishDescriptionRequired"),
                },
              }}
              error={errors.descriptionEn}
              multiline
            />
          </Grid>

          <Grid item xs={12} md={12}>
            {/* Return Policy Arabic */}
            <TextInput
              type="string"
              inputId="returnPolicyAr"
              name="returnPolicyAr"
              label={t("store.ArabicReturnPolicy")}
              rows={4}
              control={control}
              error={errors.returnPolicyAr}
              multiline={true}
              inputProps="text"
            />
          </Grid>

          <Grid item xs={12} md={12}>
            {/* Return Policy English */}
            <TextInput
              type="string"
              inputId="areturnPolicyEn"
              name="returnPolicyEn"
              label={t("store.EnglishReturnPolicy")}
              rows={4}
              control={control}
              error={errors.returnPolicyEn}
              multiline={true}
              inputProps="text"
            />
          </Grid>

          {/* Category */}
          <Grid item xs={12}>
            <SelectInput
              name="category"
              inputId="category-select"
              label={t("store.Category")}
              control={control}
              options={categoryOptions}
              rules={{
                required: {
                  value: true,
                  message: t("store.CategoryRequired"),
                },
              }}
              error={errors?.category}
            />
          </Grid>

          {/* Logo */}
          <Grid item xs={12}>
            <InputLabel
              sx={{ textAlign: "initial !important" }}
              className="label"
              variant="standard"
            >
              {t("store.Logo")}
            </InputLabel>
            <Controller
              name={`image`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <ImageUploader onChange={onChange} value={value} />
              )}
            />
          </Grid>

          {/* Working Hours */}
          <Grid item xs={12}>
            {/* start and end days work hours */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {workingHoursFields?.fields?.map((field, index) => (
                <Grid
                  container
                  columnSpacing={3}
                  marginBottom={3}
                  key={field.id}
                >
                  <Grid item xs={12} marginBottom={1}>
                    <InputLabel className="capitalize" variant="standard">
                      {mapDayIndexToDayName(field.day, i18n)}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <TimePickerInput
                      name={`workingHours[${index}].operationTimeStart`}
                      label={t("store.TimeStart")}
                      control={control}
                      rules={{}}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TimePickerInput
                      name={`workingHours[${index}].operationTimeEnd`}
                      label={t("store.TimeEnd")}
                      control={control}
                      rules={{}}
                    />
                  </Grid>
                </Grid>
              ))}
            </LocalizationProvider>
          </Grid>

          {/* Submit Buttons */}
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                sx={{ mx: 3 }}
                onClick={closeModal}
                type="button"
                size="large"
                className="formButton"
                variant="outlined"
              >
                {t("emp.Cancel")}
              </Button>

              <Button
                type="submit"
                className="buttonHandleSubmit"
                size="large"
                variant="contained"
                color="basePrimary"
              >
                {t("store.CreateStore")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
