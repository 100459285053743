import { ResetPassword } from "../../../api/auth";
import { useCustomMutation } from "../use-mutation.hook";
import { resetPasswordDto } from "../../../models/auth/resetPassword";
import { useMutation } from "react-query";

export const useResetPassword = () => {
  return useMutation({
    mutationFn: (data: resetPasswordDto) => ResetPassword(data)
  });
};
