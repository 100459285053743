import { useQuery } from "react-query";
import { ApiResponseTags, getAllTags, getTagByCategoryId } from "../../../api/tags/tags.api";
interface UseQueryParams {
  limit?: number;
  skip?: number;
}

export const useTagsQuery = ({ limit, skip }: UseQueryParams) => {
  return useQuery<ApiResponseTags, Error>(
    ["tags", skip, limit],
    () =>
      getAllTags({
        skip,
        limit,
      }),
    {}
  );
};

export const useTagByCategoryIdQuery = (
  {
    categoryId,
  }: {
    categoryId: string;
  },
  options = {}
) => {
  return useQuery(
    ["tagByCategoryId", categoryId],
    () =>
      getTagByCategoryId({
        categoryId: categoryId,
        paginate: false,
      }),
    options
  );
};
