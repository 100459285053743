import { useQuery } from 'react-query'
import { getSubcategories } from '../../../api/categories/categories.api';
import { GetPageParams } from '../../../models/page/page.model';

export const useSubcategories = (mainCategoryId?: string, params: GetPageParams = {}) => {
	return useQuery(
		['subcategories'],
		() => getSubcategories(mainCategoryId, params),
	);
}
