import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InputBox } from "../../components";

//Styles
import "./style.scss";

//Icons
import { BiArrowBack } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { useForgotPassword } from "../../@core/hooks";
import { ForgotPasswordDto } from "../../@core/models";
import { toast } from "react-toastify";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

type ForgetPasswordFormObj = {
  email: string;
};

function ForgetPassword() {
  const { mutate: forgotPassword } = useForgotPassword();
  const navigte = useNavigate();



  const onSubmit = (values: any) => {
    forgotPassword(
      { email: values.email },
      {
        onSuccess: () => {
          toast.success(`${t("auth.CheckYourEmail")}`);
          navigte("/login");
        },
        onError: () => {
          toast.error(t("auth.failedResetPassword"));
        },
      }
    );
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgetPasswordFormObj>({
    defaultValues: {
      email: "",
    },
  });


  const { t } = useTranslation();
  return (
    <div className="forgetPasswordContainer">
      <div className="container">
        <div className="logo">
          <img src="/logoDark.png" alt="logo" />
          <span>BASE</span>
        </div>
        <div>
          <h3>{t("auth.ForgotPassword")}</h3>
          <h5>{t("auth.sendEmailText")}</h5>
        </div>
        <div className="formContainer">
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth>
              <Controller
                name="email"
                control={control}
                rules={{
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: t("emp.Emailrequired"),
                  },
                  required: {
                    value: true,
                    message: t("emp.Pattern"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    label={t("auth.Email")}
                    type="string"
                    placeholder=""
                    error={!!errors.email}
                  />
                )}
              />
              <FormHelperText error={!!errors.email}>
                {errors.email?.message}
              </FormHelperText>
            </FormControl>
            {/* <InputBox
              type="text"
              placeholder={t("auth.Email")}
              value={formObj.email}
              onChange={(email: string) => setFormObj({ ...formObj, email })}
            /> */}
            <button>{t("auth.Submit")}</button>
          </form>
          <div className="navigation">
            <Link to="/login">
              <BiArrowBack size={20} />
              {t("auth.Backlogin")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
