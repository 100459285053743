import { Box, Button, Chip, MenuItem, Select, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { FileUploader } from "react-drag-drop-files";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { CgFileDocument } from "react-icons/cg";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useProduct, useUpdateProduct } from "../../../@core/hooks";
import { useCurrentMerchantSelector } from "../../../@core/slice";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import NavBar from "../../NavBar";
import "../style.scss";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../custom-inputs";
import { useTagByCategoryIdQuery } from "../../../@core/hooks/query/tag/tags.hook";
import { useQueryClient } from "react-query";
import { BasicDetailsForm } from "./basic-form";
import { ImageUploader } from "../imageUploader";
import { LoadingButton } from "@mui/lab";
import { ImageUploaderMultiple } from "../multiImageUploader";

interface Props extends React.HTMLProps<HTMLDivElement> {}

export const EditFoodProductForm: React.FC<Props> = () => {
  const navigte = useNavigate();
  const { merchant } = useCurrentMerchantSelector();
  const { productId } = useParams();
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const EngLang = i18n.language === "en";

  const { data, isLoading: isProductLoading } = useProduct({
    productId: productId as string,
    storeId: merchant!!._id,
  });
  const product = data?.data as any;
  const { data: tags } = useTagByCategoryIdQuery({
    categoryId: merchant?.category?._id||"",
  });
  const { mutate: updateProduct, isLoading: UpdateLoading } =
    useUpdateProduct();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nameEn: EngLang ? product?.name : product?.translation.name,
      nameAr: EngLang ? product?.translation?.name : product?.name,
      descriptionAr: EngLang
        ? product?.translation?.description
        : product?.description,
      descriptionEn: EngLang
        ? product?.description
        : product?.translation?.description,
      price: product?.price,
      sku: product?.sku,
      tags: product?.tags?.map((tag: any) => tag._id) || [],
      mainImage: product?.mainImage,
      images: product?.images || [],
      currency: product?.currency,
      addOns:
        product?.addOns?.map((item: any) => ({
          _id: item._id,
          nameEn: EngLang ? item?.name : item?.translation.name ?? "",
          nameAr: EngLang ? item?.translation?.name : item?.name??"",
          price: item.price ?? 0,
          image: item?.image,
        })) || [],
    },
  });

  const addOnsField = useFieldArray<any>({
    name: "addOns",
    control,
  });
  const addAnotherAddOn = () => {
    addOnsField.append({
      nameAr: "",
      nameEn: "",
      price: "",
      image: null,
    });
  };
  const processAddOns = (
    addOns:
      | {
          image?: File | string;
          nameAr?: string;
          nameEn?: string;
          price?: number;
        }[]
      | undefined,
    formData: FormData
  ) => {
    const addOnsData = addOns?.map((addOn, index) => {
      const { image, ...rest } = addOn;
      const addOnData: {
        nameAr?: string;
        nameEn?: string;
        price?: number;
        image?: string;
      } = { ...rest };

      if (image instanceof File) {
        formData.append(`addOns[${index}].image`, image);
      } else {
        addOnData.image = image;
      }

      return addOnData;
    });

    return addOnsData;
  };

  const processImages = (
    images: (File | string)[] | undefined,
    formData: FormData
  ) => {
    const imagesData: (string | File)[] = [];

    images?.forEach((image, index) => {
      if (image instanceof File) {
        formData.append(`images[${index}]`, image);
      } else {
        imagesData.push(image);
      }
    });

    return imagesData;
  };

  const appendFormData = (values: any, formData: FormData) => {
    Object.entries(values).forEach(([key, value]) => {
      if (key === "addOns" || key === "images") {
        return;
      }
      if (key === "tags") {
        formData.append("tags", JSON.stringify(values.tags || []));
        return;
      }
      if (typeof value === "string" || value instanceof Blob) {
        formData.append(key, value);
      }
    });
  };
  const onSubmit = async (values: any) => {
    const booleanValue = true;
    const formData = new FormData();
    formData.append("isMenuProduct", booleanValue.toString());

    // Process addOns
    const addOnsData = processAddOns(values?.addOns, formData);
    formData.append("addOns", JSON.stringify(addOnsData));

    // Process images
    const imagesData = processImages(values?.images, formData);
    formData.append("images", JSON.stringify(imagesData));

    // Append other form data fields
    appendFormData(values, formData);

    updateProduct(
      {
        storeId: merchant?._id,
        productId,
        body: formData,
      },
      {
        onSuccess: () => {
          toast.success(t("product.updateSuccess"));
          queryClient.invalidateQueries("product");
          navigte(`/products/${productId}`);
        },
        onError: () => {
          toast.error(t("product.updateFaild"));
        },
      }
    );
  };

  if (isProductLoading) {
    return (
      <div className="content">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="stock">
      <NavBar
        title={t("product.EditProduct")}
        status={product?.status}
        hasBack={true}
      />

      <div className="editProductAction">
        <div className="routes">
          <span onClick={() => navigte("/products")}>
            {t("product.Products")}
          </span>
          <IoIosArrowForward fontWeight={900} size={20} />
          <span>
            {t("product.Editing")}
            <span
              style={{ color: "sandybrown", margin: "0px 10px" }}
              onClick={() => navigte(`/products/${productId}`)}
            >
              {product?.name}
            </span>
          </span>
        </div>
      </div>

      <div className="content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Box>
              <BasicDetailsForm control={control} errors={errors} t={t} />
              <br />
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{
                  xs: 1,
                  sm: 2,
                  md: 3,
                }}
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                  marginTop: "20px",
                }}
              >
                <Grid item className="header" md={12} xs={12}>
                  <CgFileDocument />
                  {t("product.ProductImage")}
                </Grid>

                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={2}
                    paddingRight={3}
                    paddingLeft={3}
                    marginTop={2}
                    marginBottom={2}
                  >
                    {/* Image */}
                    <Grid item xs={12}>
                      <Controller
                        name="mainImage"
                        control={control}
                        defaultValue={null}
                        render={({ field: { onChange, value } }) => (
                          <ImageUploader onChange={onChange} value={value} />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <br />
              {/* Tags */}
              <section className="form-section">
                <header className="header">
                  <CgFileDocument />
                  <h2>{t("product.Tags")}</h2>
                </header>

                <div className="section-body">
                  <Grid container>
                    <Grid item xs={12}>
                      {/* tags */}
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Controller
                            name="tags"
                            control={control}
                            render={({ field }) => (
                              <Select
                                size="small"
                                sx={{
                                  borderRadius: "12px",
                                }}
                                placeholder={t("product.Tags")}
                                displayEmpty
                                id="tags-type-select"
                                labelId="tags-type-select-label"
                                error={!!errors.tags}
                                multiple
                                value={field.value || []}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                inputRef={field.ref}
                                renderValue={(selected) => {
                                  if (
                                    !selected ||
                                    (Array.isArray(selected) &&
                                      selected?.length === 0)
                                  ) {
                                    return (
                                      <span
                                        style={{
                                          color: "#aaa",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {t("product.Tags")}
                                      </span>
                                    );
                                  }

                                  const selectedOptions = tags?.data?.filter(
                                    (option: any) =>
                                      selected?.includes(option._id)
                                  );

                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 4,
                                      }}
                                    >
                                      {selectedOptions?.map((option: any) => (
                                        <Chip
                                          key={option?._id}
                                          label={option?.name}
                                        />
                                      ))}
                                    </div>
                                  );
                                }}
                              >
                                {tags?.data?.map((tag: any) => (
                                  <MenuItem key={tag?._id} value={tag?._id}>
                                    {tag?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </section>

              {/* Meal Images */}
              <section className="form-section">
                <header className="header">
                  <CgFileDocument />
                  <h2>{t("product.MealImages")}</h2>
                </header>

                <div className="section-body">
                  <Controller
                    name="images"
                    control={control}
                    defaultValue={[]}
                    render={({ field: { onChange, value } }) => (
                      <ImageUploaderMultiple
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </div>
              </section>
              <br />
              {/* Add Ons */}
              <section className="form-section">
                <header className="header">
                  <CgFileDocument />
                  <h2>{t("product.Add ons")}</h2>
                </header>

                <div className="section-body">
                  {addOnsField?.fields?.map((field, index) => (
                    <div className="addon-item">
                      <Grid key={field.id} container spacing={2} mb={4}>
                        <Grid item xs={4}>
                          <TextInput
                            inputId="add-on-name-ar"
                            name={`addOns.${index}.nameAr`}
                            label={t("product.NameArabic")}
                            control={control}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextInput
                            inputId="add-on-name-en"
                            name={`addOns.${index}.nameEn`}
                            label={t("product.NameEnglish")}
                            control={control}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextInput
                            type="number"
                            inputId="add-on-price"
                            name={`addOns.${index}.price`}
                            label={t("product.price")}
                            placeholder="10KD"
                            rules={{
                              required: {
                                value: true,
                                message: t("product.Required"),
                              },
                            }}
                            control={control}
                          />
                        </Grid>

                        <Grid margin={"auto"} my={2} item xs={12} md={8}>
                          <Controller
                            name={`addOns.${index}.image`}
                            control={control}
                            defaultValue={null}
                            render={({ field: { onChange, value } }) => (
                              <ImageUploader
                                onChange={onChange}
                                value={value}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            className="formButton"
                            color="error"
                            onClick={() => addOnsField.remove(index)}
                          >
                            {t("product.Remove")}
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                  <Box display="flex" justifyContent="flex-end" gap={2}>
                    <Button
                      onClick={() => addAnotherAddOn()}
                      variant="contained"
                      className="buttonHandleSubmit"
                      disableElevation
                    >
                      {t("product.Add another add on")}
                    </Button>
                  </Box>
                </div>
              </section>

              <br />

              {/* Cancel and Sumbit */}
              <Grid item xs={12} padding={1} margin={1}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  gap={2}
                >
                  <Button
                    type="button"
                    className="formButton"
                    size="large"
                    variant="outlined"
                    color="error"
                    onClick={() => navigte(`/products/${productId}`)}
                  >
                    {t("product.Cancel")}
                  </Button>
                  <LoadingButton
                    type="submit"
                    size="large"
                    className="buttonHandleSubmit"
                    loading={UpdateLoading}
                    disabled={UpdateLoading}
                    variant="contained"
                  >
                    {t("product.UpdateProduct")}
                  </LoadingButton>
                  {/* <Button
                    type="submit"
                    className="buttonHandleSubmit"
                    size="large"
                    variant="contained"
                    color="basePrimary"
                    disabled={UpdateLoading}
                  >
                    {t("product.UpdateProduct")}
                  </Button> */}
                </Stack>
              </Grid>
            </Box>
          </div>
        </form>
      </div>
    </div>
  );
};
