import { useMutation } from 'react-query';
import { addColor, deleteColor, editColor } from '../../../api/colors/colors.api';

export const useAddColor = () => {
	return useMutation(addColor);
};

export const useDeleteColor = () => {
	return useMutation(deleteColor);
};

export const useEditColor = () => {
	return useMutation(editColor);
};
