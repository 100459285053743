import {
  Box,
  FormHelperText,
  IconButton,
  InputLabel,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, FieldError } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import DeleteIcon from "@mui/icons-material/Delete";

import "./upload-images-preview.component.scss";
import { useTranslation } from "react-i18next";

interface Props extends React.HTMLProps<HTMLDivElement> {
  name: string;
  label: string;
  control: any;
  maxNumber?: number;
  isMultiple?: boolean;
  rules?: { [key: string]: any };
  error?: FieldError;
}

export const UploadImagesPreview: React.FC<Props> = ({
  name,
  label,
  control,
  maxNumber,
  isMultiple = false,
  rules = {},
  error,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        const { ref, ...restField } = field;
        return (
          <>
            {/* <InputLabel id={`${name}-label`}>{label}</InputLabel> */}

            <ImageUploading
              {...restField}
              multiple={isMultiple}
              maxNumber={maxNumber}
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  <button
                    type="button"
                    className={`upload__image-dropzone ${
                      !!error ? "upload--error__image-dropzone" : ""
                    }`}
                    style={
                      isDragging
                        ? {
                            color: "red",
                          }
                        : undefined
                    }
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <Box textAlign={"center"}>
                      <div className="upload__image-dropzone-btn">
                        {t("store.AddFiles")}
                      </div>
                      <Typography
                        sx={{ color: "#6D7175", my: 1 }}
                        className="image-accept"
                      >
                        {t("product.Accepts")} .png and .jpg
                      </Typography>
                    </Box>
                  </button>

                  {!!error ? (
                    <FormHelperText error>{error?.message}</FormHelperText>
                  ) : null}
                  <div className="upload__image-list">
                    {Array.isArray(imageList) &&
                      imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img
                            onClick={() => onImageUpdate(index)}
                            src={
                              typeof image === "string" ? image : image.dataURL
                            }
                            alt=""
                            width="100"
                            style={{
                              borderRadius: "8px",
                              height: "auto",
                              objectFit: "cover",
                            }}
                          />
                          <div className="image-item__btn-wrapper">
                            <div className="image-item__btn-remove">
                              <IconButton
                                type="button"
                                aria-label="delete"
                                onClick={() => onImageRemove(index)}
                              >
                                <DeleteIcon color="warning" />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </ImageUploading>
          </>
        );
      }}
    />
  );
};
