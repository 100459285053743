import { useTranslation } from "react-i18next";
import { StoreDiscountTypesEnum } from "../../@core/models/discount/discount-types.enum";

export const StoreDiscountTypes = () => {
  const { t } = useTranslation();

  return [
    {
      value: StoreDiscountTypesEnum.STORE_DISCOUNT,
      label: t("app.StoreDiscount"),
    },
    {
      value: StoreDiscountTypesEnum.CATEGORY_DISCOUNT,
      label: t("app.CategoryDiscount"),
    },
    {
      value: StoreDiscountTypesEnum.SUB_CATEGORY_DISCOUNT,
      label: t("app.SubCategoryDiscount"),
    },
    {
      value: StoreDiscountTypesEnum.PRODUCT_DISCOUNT,
      label: t("app.ProductDiscount"),
    },
  ];
};
export const getTranslatedDiscountType = (type:string,t:any) => {
 switch (type) {
   case "ADMIN_DISCOUNT":
     return t("app.AdminDiscount");
   case "STORE_DISCOUNT":
     return t("app.StoreDiscount");
   case "CATEGORY_DISCOUNT":
     return t("app.CategoryDiscount");
   case "SUB_CATEGORY_DISCOUNT":
     return t("app.SubCategoryDiscount");
   case "PRODUCT_DISCOUNT":
     return t("app.ProductDiscount");
   default:
     return type;
 }
};