import { useQuery } from "react-query";
import { fetcher } from "../../api/fetcher.api";

interface Props {
  url: string;
  dependedKey?: string;
  page?: number;
  limit?: number;
  dependedParams?: string[] | number[] | boolean[];
  options?: any;
  paginated?: boolean;
  search?: string;
  isActive?: boolean | undefined;
  type?: string | undefined;
  status?: string | undefined;
}

export const useCustomQuery = <T>({
  url,
  dependedKey,
  page = 1,
  limit,
  dependedParams,
  options,
  paginated = true,
  search,
  isActive,
  type,
  status,
}: Props) => {
  const params = dependedParams?.length ? { ...dependedParams } : [];
  const skip = limit && page ? limit * page : 0;
  const paramsOption: any = {
    limit,
    skip,
    paginated,
    search,
    ...(isActive !== undefined && { isActive }),
    ...(type !== undefined && { type }),
    ...(status !== undefined && { status }),
  };
  return useQuery<T, Error>(
    [dependedKey || url, params],
    (): Promise<T> => fetcher(`/${url}`, paramsOption),
    options
  );
};
