import React, { useEffect } from "react";
import {
  TextInput,
  SelectInput,
  ColorPickerInput,
} from "../../components/custom-inputs";
import { useForm } from "react-hook-form";
import { Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSubcategories } from "../../@core/hooks";
import { useCurrentMerchantSelector } from "../../@core/slice";
import { useTranslation } from "react-i18next";
import "./style.scss"
interface Props extends React.HTMLProps<HTMLDivElement> {
  closeModal: () => void;
  submitFormHandler: (data: any) => void;
  isSubmitting: boolean;
  selectedItem: any;
}

export const AddColorForm: React.FC<Props> = ({
  closeModal,
  submitFormHandler,
  isSubmitting,
  selectedItem,
}) => {
  const { merchant } = useCurrentMerchantSelector();
  const { t } = useTranslation();
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: "",
      code: "",
      category: merchant?.category?._id||"",
      subCategory: null,
    },
  });

  const { data: subCategories } = useSubcategories(merchant?.category?._id||"");

  const subCategoryOptions = subCategories?.data?.map((subCategory) => ({
    label: subCategory.name,
    value: subCategory._id,
  }));

  useEffect(() => {
    if (selectedItem) {
      setValue("name", selectedItem.name||"");
      setValue("code", selectedItem.code||"");
      setValue("subCategory", selectedItem.subCategory._id);
    }
  }, [selectedItem, setValue]);

  return (
    <form onSubmit={handleSubmit(submitFormHandler)}>
      <Box sx={{ marginBottom: "1rem" }}>
        <TextInput
          control={control}
          inputId="color-name"
          name="name"
          label={t("app.ColorName")}
        />
      </Box>

      <Box sx={{ marginBottom: "1rem" }}>
        <ColorPickerInput
          control={control}
          inputId="color-code"
          name="code"
          label={t("app.ColorCode")}
          format="hex"
          isAlphaHidden={true}
        />
      </Box>

      <Box sx={{ marginBottom: "1rem" }}>
        <SelectInput
          inputId="subCategory"
          name={"subCategory"}
          label={t("app.ChooseSubcategory")}
          options={subCategoryOptions}
          control={control}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "12px",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <LoadingButton
          type="submit"
          size="large"
          className="buttonHandleSubmit"
          loading={isSubmitting}
          disabled={isSubmitting}
          variant="contained"
        >
          {selectedItem ? t("app.EditColor") : t("app.AddColor")}
        </LoadingButton>

        <Button
          type="button"
          variant="outlined"
          className="formButton"
          size="large"
          onClick={() => closeModal()}
        >
          {t("product.Cancel")}
        </Button>
      </Box>
    </form>
  );
};
