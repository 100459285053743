export const foodFormFields = (t: any, errors: any) => [
  {
    id: "nameAr",
    name: "nameAr",
    label: t("product.ProductNameArabic"),
    rules: {
      required: {
        value: true,
        message: t("product.ArabicNamerequired"),
      },
    },
    error: errors.nameAr,
    type: "text",
  },
  {
    id: "nameEn",
    name: "nameEn",
    label: t("product.ProductNameEnglish"),
    rules: {
      required: {
        value: true,
        message: t("product.EnglishNamerequired"),
      },
    },
    error: errors.nameEn,
    type: "text",
  },
  {
    id: "price",
    name: "price",
    label: t("product.Price"),
    rules: {
      required: {
        value: true,
        message: t("product.Required"),
      },
    },
    error: errors.price,
    type: "number",
  },
  {
    id: "sku",
    name: "sku",
    label: t("product.SKU"),
    type: "text",
  },
  {
    id: "descriptionAr",
    name: "descriptionAr",
    label: t("product.ProductDescriptionArabic"),
    rules: {
      required: {
        value: true,
        message: t("product.ArabicDescriptionRequired"),
      },
    },
    error: errors.descriptionAr,
    type: "text",
    multiline: true,
    rows: 4,
  },
  {
    id: "descriptionEn",
    name: "descriptionEn",
    label: t("product.ProductDescriptionEnglish"),
    rules: {
      required: {
        value: true,
        message: t("product.EnglishDescriptionRequired"),
      },
    },
    error: errors.descriptionEn,
    type: "text",
    multiline: true,
    rows: 4,
  },
];

