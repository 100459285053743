import React, { useEffect } from 'react';
import {
	TextInput,
	SelectInput,
} from '../../components/custom-inputs';
import { useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSubcategories } from '../../@core/hooks';
import { useCurrentMerchantSelector } from '../../@core/slice';
import { useTranslation } from 'react-i18next';
import "./style.scss";
interface Props extends React.HTMLProps<HTMLDivElement> {
	closeModal: () => void;
	submitFormHandler: (data: any) => void;
	isSubmitting: boolean;
	selectedItem: any;
}

export const AddSizesForm: React.FC<Props> = ({
	closeModal,
	submitFormHandler,
	isSubmitting,
	selectedItem,
}) => {
	const { merchant } = useCurrentMerchantSelector();
    const {t}=useTranslation()
	const { control, handleSubmit, setValue } = useForm({
		defaultValues: {
			size: '',
			category: merchant?.category?._id||"",
			subCategory: null,
		},
	});

	const { data: subCategories } = useSubcategories(
		merchant?.category?._id||""
	);

	const subCategoryOptions = subCategories?.data?.map(
		(subCategory) => ({
			label: subCategory.name,
			value: subCategory._id,
		})
	);

	useEffect(() => {
		if (selectedItem) {
			setValue('size', selectedItem.size);
			setValue(
				'subCategory',
				selectedItem.subCategory._id
			);
		}
	}, [selectedItem, setValue]);

	return (
    <form onSubmit={handleSubmit(submitFormHandler)}>
      <Box sx={{ marginBottom: "1rem" }}>
        <TextInput
          control={control}
          inputId="size-name"
          name="size"
          label={t("size.sizeName")}
        />
      </Box>

      <Box sx={{ marginBottom: "1rem" }}>
        <SelectInput
          inputId="subCategory"
          name={"subCategory"}
          label={t("size.ChooseSubcategory")}
          options={subCategoryOptions}
          control={control}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "12px",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <LoadingButton
          type="submit"
          className="buttonHandleSubmit"
          size="large"
          loading={isSubmitting}
          variant="contained"
        >
          {selectedItem ? t("size.editSize") : t("size.AddSize")}
        </LoadingButton>
        <Button
          type="button"
          className="formButton"
          variant="outlined"
          size="large"
          onClick={() => closeModal()}
        >
          {t("size.Cancel")}
        </Button>
      </Box>
    </form>
  );
};
