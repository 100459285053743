import instance from "../../utils/axios.util";

export async function fetcher(url: string, params: {[key: string]: any} = {}) {
  try {
    const response = await instance.get(url, {
      params
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}
