import { UpdateOrderStatus } from "../../../api";
import { useCustomMutation } from "../use-mutation.hook";

interface UpdateOrderStatusRequest {
  storeId: string;
  status: string;
  orderId: string;
}

export const useUpdateOrderStatus = () => {
  return useCustomMutation<UpdateOrderStatusRequest, any>({
    url: "/update/order/status",
    api: UpdateOrderStatus,
  });
};
