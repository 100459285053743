import dayjs from 'dayjs';
import { Category } from "../category/category";
import { User } from "../user/user";

interface Location {
  type?: string;

  coordinates: number[];
}

export interface WorkingHours {
	day: number;
	operationTimeStart: dayjs.Dayjs;
	operationTimeEnd: dayjs.Dayjs;
}

export enum STORE_STATUS {
  STORE_OPEN_STATUS = "Open",
  STORE_BUSY_STATUS = "Busy",
  STORE_CLOSED_STATUS = "Closed",
}

export interface Store {
  modifiedBy?: string | User;

  owner: string | User;

  category: string | Category;

  name: string;

  workingHours: WorkingHours[];

  description: string;

  image: string;

  openStatus: STORE_STATUS;

  location: Location;

  isDeleted?: boolean;

  returnPolicy?: string;

  exchangePolicy?: string;

  type: string;
}

export interface StoreFormData {
  category: string;
  nameAr?: string;
  nameEn?: string;
  descriptionAr?: string;
  descriptionEn?: string;
  image: File | string;
  workingHours: Array<WorkingHours>;
  longitude: number;
  latitude: number;
  returnPolicyAr?: string;
  exchangePolicyAr?: string;
  returnPolicyEn?: string;
  exchangePolicyEn?: string;
}

export interface updateStoreDto extends Store {}

export interface GetStoreDto {
  _id: string;
  owner: string;
  category: {
    _id: string;
    name: string;
  };
  name: string;
  workingHours: WorkingHours[];
  description: string;
  image: string;
  openStatus: string;
  location: {
    type: string;
    coordinates: number[];
  };
  type: string;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  translation?: {
    description?: string;
    name?: string;
  };
}
export interface StoreResponse {
  success: boolean;
  data: GetStoreDto;
}
