import { UpdateProduct } from "../../../api";
import { ProductResponse } from "../../../models";
import { useCustomMutation } from "../use-mutation.hook";

export const useUpdateProduct = () => {
  return useCustomMutation<any, ProductResponse>({
    url: "/update/store/products",
    api: UpdateProduct,
  });
};
