import { GrFormClose } from "react-icons/gr";
import { Tag } from "../../@core/models";

//Styles
import "./style.scss";
import { useTranslation } from "react-i18next";

type TagSelectorProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  data: Tag[];
  onChange: (tag: Tag) => void;
  selected: Tag[];
};

function TagSelector({
  visible,
  setVisible,
  data,
  selected,
  onChange,
}: TagSelectorProps) {
  const {t}=useTranslation()
  return visible ? (
    <div className="tagSelectorContainer">
      <div className="container">
        <div className="header">
          <div>
            <GrFormClose size={30} onClick={() => setVisible(false)} />
          </div>
          <h2>{t("app.Select Tags")}</h2>
        </div>
        <hr />
        <div className="formContainer">
          <form>
            {data?.map((tag) => (
              <div className="greenInput">
                <section className="roundedOneI">
                  <div className="roundedOne">
                    <input
                      type="checkbox"
                      value={tag._id}
                      id={tag._id}
                      name={tag.name}
                      checked={
                        selected.length === 0
                          ? false
                          : selected?.find((t) => t?._id === tag?._id)
                          ? true
                          : false
                      }
                      onChange={(_) => onChange(tag)}
                    />
                    <label htmlFor={tag?._id}></label>
                  </div>
                  <br />
                </section>
                <span className="label">{tag?.name}</span>
              </div>
            ))}
          </form>
        </div>
      </div>
    </div>
  ) : null;
}

export default TagSelector;
