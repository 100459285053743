import { useMutation, UseMutationOptions } from "react-query";

interface Props {
  url: string;
  api: (inputs: any, accessToken?: string) => Promise<any>;
  options?:
    | Omit<
        UseMutationOptions<any, Error, any, any>,
        "mutationFn" | "mutationKey"
      >
    | undefined;
}
// --> I for input
// --> R for Response
export const useCustomMutation = <I, R>({ url, api, options }: Props) => {
  return useMutation<R, Error, I, any>(
    url,
    async (mutateBody: I) => {
      const res = await api(mutateBody);
      return res;
    },
    options
  );
};
