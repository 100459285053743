import { CreateDiscount } from "../../../api";
import { Discount } from "../../../models";
import { useCustomMutation } from "../use-mutation.hook";

export const useCreateDiscount = () => {
  return useCustomMutation<
		{
			storeId: string;
			body: FormData;
		},
		Discount
  >({
		url: 'create/discount',
		api: CreateDiscount,
  });
};
