import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { IoCloseCircleOutline } from "react-icons/io5";
import "./style.scss"
interface Props extends React.HTMLProps<HTMLDivElement> {
  title: string;
  description: string;
  isOpen: boolean;
  isLoading: boolean;
  handleClose: () => void;
  handleConfirm?: () => void;
}

export const ConfirmModal: React.FC<Props> = ({
  isOpen,
  title,
  description,
  isLoading,
  handleClose,
  handleConfirm,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ borderRadius: 16 }}
      className="confirm-delete"
    >
      <Box sx={{ textAlign: "end", m: 2 }}>
        <IconButton onClick={handleClose}>
          <IoCloseCircleOutline />
        </IconButton>
      </Box>
      <Box className="PaperProps">
        <DialogTitle>
          <Typography className="dialogTitle">{title}</Typography>
        </DialogTitle>

        <DialogContent>
          <Typography className="dialogContent">{description}</Typography>
        </DialogContent>

        <DialogActions sx={{}}  className="dialogActions">
          <Button
            className="formButton"
            onClick={handleClose}
            disabled={isLoading}
          >
            {t("product.Cancel")}
          </Button>
          {handleConfirm && (
            <LoadingButton
              loading={isLoading}
              onClick={handleConfirm}
              disabled={isLoading}
              autoFocus
              className="buttonHandleSubmit"
            >
              {t("app.Confirm")}
            </LoadingButton>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};
