import instance from "../../../utils/axios.util";
import { Product } from "../../models";

interface UpdateProductDto {
  storeId: string;
  productId: string;
  body: Product;
}

export async function UpdateProduct({
  storeId,
  productId,
  body,
}: UpdateProductDto) {
  try {
    const res = await instance.patch(`${storeId}/product/${productId}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}
