import { Login } from "../../../api/auth";
import { LoginDto, LoginResponse } from "../../../models/auth/login";
import { useCustomMutation } from "../use-mutation.hook";

export const useLogin = () => {
  return useCustomMutation<LoginDto, LoginResponse>({
    url: "/auth/login",
    api: Login,
  });
};
