import { ForgotPassword } from "../../../api/auth";
import { useCustomMutation } from "../use-mutation.hook";
import { ForgotPasswordDto } from "../../../models/auth/forgotPassword";
 
export const useForgotPassword = () => {
  return useCustomMutation<ForgotPasswordDto, any>({
    url: "/request-forget-password",
    api: ForgotPassword,
  });
};
