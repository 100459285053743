import { Box, Grid, TextField, Stack, Button, Avatar } from "@mui/material";
import React, { Fragment } from "react";
import { theme } from "../../../assets/styles/mui-theme";
import { Employee } from "../../../@core/models";
import { useTranslation } from "react-i18next";
import "./style.scss";

interface Props extends React.HTMLProps<HTMLDivElement> {
  closeModal: () => void;
  selectedEmployee?: Employee;
}

export const ViewEmployee: React.FC<Props> = ({
  closeModal,
  selectedEmployee,
}) => {
  const { t } = useTranslation();
  const getInitials = (name: any) => {
    const nameArray = name.split(" ");
    const initials = nameArray.map((n: any) => n.charAt(0));
    return initials;
  };
  return (
    <Fragment>
      <Box>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={12}>
            {/* Employee avatar */}
            <Box
              width={"100%"}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <div>
                <Box display={"block"}>
                  {selectedEmployee?.avatar ? (
                    <Avatar
                      src={selectedEmployee?.avatar}
                      alt={selectedEmployee?.name}
                      sx={{ width: 110, height: 110 }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        width: 110,
                        height: 110,
                        backgroundColor: "#ffc96b",
                        color: "#3d2800",
                        fontSize: "35px",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                    >
                      {getInitials(selectedEmployee?.name)}
                    </Avatar>
                  )}
                </Box>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              disabled
              className="textField"
              defaultValue={selectedEmployee?.name}
              label={t("emp.EmployeeName")}
              type="string"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Employee Email */}
            <TextField
              fullWidth
              disabled
              className="textField"
              defaultValue={selectedEmployee?.email}
              label={t("emp.EmployeeEmail")}
              type="string"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              disabled
              className="textField"
              defaultValue={selectedEmployee?.job}
              label={t("emp.EmployeeJob")}
              type="string"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              disabled
              className="textField"
              defaultValue={`${selectedEmployee?.countryCode}${selectedEmployee?.phoneNumber}`}
              label={t("emp.EmployeePhoneNumber")}
              type="string"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              disabled
              className="textField"
              defaultValue={selectedEmployee?.status}
              label={t("emp.EmployeeStatus")}
              type="string"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={3}
            >
              <Button
                type="button"
                className="formButton"
                size="large"
                variant="outlined"
                color="error"
                onClick={closeModal}
              >
                {t("emp.Cancel")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
