import { OrderResponse } from "../../../models";
import { useCustomQuery } from "../use-query.hook";

interface Props {
  orderId: string;
  storeId: string;
  options?: any;
}

export const useOrder = ({ storeId, orderId, options }: Props) =>
  useCustomQuery<OrderResponse>({
    url: `${storeId}/order/${orderId}`,
    options,
  });
