import { Link } from "react-router-dom";
import "./style.scss";
import { useTranslation } from "react-i18next";

const Error: React.FC = () => {
  	const { t } = useTranslation();

  return (
    <div className="error" id="error">
      <h1>{t("app.404")}</h1>
      <h2>{t("app.PageNotfound")}</h2>
      <p>{t("app.ErrNotFound")}</p>
      <Link to="/">{t("app.GoToHome")}</Link>
    </div>
  );
};

export default Error;
