import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { FaUserEdit } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import FloatingForm from "../FloatingForm";
import { EditSeller } from "./editProfileSeller";

type ProfileMenuProps = {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
};

export const ProfileMenu: React.FC<ProfileMenuProps> = ({
  anchorEl,
  open,
  handleClose,
}) => {
  const { t } = useTranslation();
  const [formVisible, setFormVisible] = useState(false);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            setFormVisible(true);
            handleClose();
          }}
        >
          <FaUserEdit size={20} color="gray" style={{ margin: "0px 5px" }} />
          {t("profile.EditProfile")}
        </MenuItem>
      </Menu>
      <FloatingForm
        visible={formVisible}
        setVisible={() => setFormVisible(false)}
        title={t("profile.EditProfile")}
        body={<EditSeller closeModal={() => setFormVisible(false)} />}
      />
    </>
  );
};
