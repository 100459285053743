import { store } from "./../store/store";
import axios, { AxiosError } from "axios";
import { type AxiosRequestHeaders } from "axios";
import { logOut } from "../@core/slice";

const API_ENDPOINT_BASE = process.env.REACT_APP_API_URL + "/seller";

const instance = axios.create({
  baseURL: API_ENDPOINT_BASE,
});

instance.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      "Accept-Language": localStorage.getItem("i18nextLng") || "en",
    } as unknown as AxiosRequestHeaders;
    const token = store.getState()?.auth?.user?.accessToken;

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      } as unknown as AxiosRequestHeaders;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      store.dispatch(logOut()); // Logout user
      window.location.href = "/login"; // Redirect to login page
    }
    return Promise.reject(error);
  }
);

export default instance;
