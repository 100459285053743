import { useQuery } from 'react-query';
import { getDiscountById } from '../../../api';
import { Discount } from '../../../models';

export const useGetDiscount = ({
	storeId,
	discountId,
}: {
	storeId: string;
	discountId: string;
}) => {
	return useQuery<unknown, Error, Discount>(
		['discount', storeId, discountId],
		() => getDiscountById({ storeId, discountId })
	);
};
