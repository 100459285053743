// statusHelpers.ts

import { TFunction } from "i18next";

export const getStatusClassName = (status: string, isRTL: boolean): string => {
  const baseClass = isRTL ? "Rtl" : "";
  switch (status) {
    case "Pending":
      return `pendingStatus${baseClass}`;
    case "Delivered":
    case "Approved":
      return `approvedStatus${baseClass}`;
    case "Accepted":
    case "Packing":
    case "Placed":
    case "Shipping":
      return `acceptedStatus${baseClass}`;
    case "Cancelled":
    case "Rejected":
      return `cancelledStatusRTl`;
    default:
      return "";
  }
};

export const getStatusTranslation = (status: string, t: TFunction): string => {
  const statusMap: { [key: string]: string } = {
    Pending: t("status.Pending"),
    Delivered: t("status.Delivered"),
    Accepted: t("status.Accepted"),
    Packing: t("status.Packing"),
    Placed: t("status.Placed"),
    Shipping: t("status.Shipping"),
    Cancelled: t("status.Cancelled"),
    Rejected: t("status.Rejected"),
    Approved: t("status.Approved"),
  };
  return statusMap[status] || status;
};
