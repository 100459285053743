import { useQuery } from "react-query";
import { ApiResponseSubCatg, getSubcategories } from "../../../api/categories/categories.api";
interface UseQueryParams {
  limit?: number;
  skip?: number;
}

export const useGetAllSubCategories = ({ limit, skip }: UseQueryParams) => {
  return useQuery<ApiResponseSubCatg, Error>(
    ["sub-categories", skip, limit],
    () => getSubcategories("", { skip, limit }),
  );
};