import React, { useEffect, useState } from "react";
import { SelectBox, InputBox } from "../../components";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Box,
  Grid,
  FormHelperText,
  TextField,
  Stack,
  Button,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { useForm, Controller } from "react-hook-form";
import { useSubcategories } from "../../@core/hooks/query/discount/get-sub-categories.hook";
import { DatePickerInput } from "../../components/date-picker/date-picker-input.component";
import { TimePickerInput } from "../../components/time-picker/time-picker.component";
import { addTimeToDate } from "../../utils/dayjs.util";
import { PercentageNumber } from "../../utils/regex-patterns.util";
import { DiscountFormData } from "../../@core/models";
import { StoreDiscountTypes } from "./discount-types";
import { useListallProducts, useStore } from "../../@core/hooks";
import { Discount as DiscountModel } from "../../@core/models";
import { useGetDiscount } from "../../@core/hooks/query/discount/get-discount.hook";
import { useEditDiscount } from "../../@core/hooks/mutation/discount/edit-discount.hook";
import { StoreDiscountTypesEnum } from "../../@core/models/discount/discount-types.enum";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { SelectInput } from "../../components/custom-inputs";
import dayjs from "dayjs";
import "./style.scss";

interface Props extends React.HTMLProps<HTMLDivElement> {
  closeModal: () => void;
  selectedDiscount?: DiscountModel;
}

export const EditDiscountForm: React.FC<Props> = ({
  closeModal,
  selectedDiscount,
}) => {
  const { t } = useTranslation();
  const { data: store } = useStore();
  const { data: discount } = useGetDiscount({
    storeId: store?.data?._id || "",
    discountId: selectedDiscount?._id || "",
  });
  const { mutate: editDiscount, isLoading } = useEditDiscount();
  const { data: productsList } = useListallProducts({
    storeId: store?.data?._id || "",
    paginated: false,
  });
  const { data: subCategories } = useSubcategories(store?.data?.category?._id);
  const optionsSubCategories = subCategories?.data?.map((item) => ({
    label: item?.name,
    value: item?._id,
  }));
  const optionsProduct = productsList?.data?.map((item: any) => ({
    label: item?.name,
    value: item?._id,
  }));
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<DiscountFormData>({
    defaultValues: {
      type: "",
      store: store?.data._id,
      discount: undefined,
      image: undefined,
      category: store?.data?.category._id,
      subCategory: "",
      product: "",
      startDate: dayjs(),
      endDate: dayjs(),
      startTime: dayjs(),
      endTime: dayjs(),
    },
  });
  const queryClient = useQueryClient();
  const typeChange = watch("type");
  const watchImage = watch("image");

  const onSubmit = (data: DiscountFormData) => {
    const startDate = addTimeToDate(
      data.startDate || new Date(),
      data.startTime || new Date().getTime()
    ).toISOString();

    const endDate = addTimeToDate(
      data.endDate || new Date(),
      data.endTime || new Date().getTime()
    ).toISOString();

    // remove startTime and endTime from data
    delete data.startTime;
    delete data.endTime;

    const body = {
      ...data,
      store: store?.data._id,
      discount: data?.discount,
      category: store?.data?.category?._id,
      startDate,
      endDate,
    };

    if (data.type === "STORE_DISCOUNT") {
      delete body.category;
      delete body.subCategory;
      delete body.product;
    } else if (data.type === "CATEGORY_DISCOUNT") {
      delete body.subCategory;
      delete body.product;
    } else if (data.type === "SUB_CATEGORY_DISCOUNT") {
      delete body.product;
    } else if (data.type === "PRODUCT_DISCOUNT") {
      delete body.subCategory;
    }

    const formData = new FormData();

    if (watchImage instanceof File) {
      formData.append("image", watchImage);
    } else if (typeof watchImage === "string") {
      delete body.image;
    }

    // Append other fields
    Object.entries(body).forEach(([key, value]) => {
      if (key !== "image") {
        // Exclude image from generic appending
        formData.set(key, value);
      }
    });
    editDiscount(
      {
        storeId: store?.data._id,
        discountId: discount?._id,
        body: formData,
      },
      {
        onSuccess: () => {
          toast.success(t("success.successfullyUpdated"));
          queryClient.invalidateQueries(`discount/store/${store?.data._id}`);
          queryClient.invalidateQueries("discount");
          closeModal();
        },
        onError: () => {
          toast.error(t("errors.faildUpdated"));
        },
      }
    );
  };

  const handleImageChange = (file: File) => {
    setValue("image", file);
  };

  useEffect(() => {
    if (discount) {
      reset({
        type: discount?.type,
        discount: discount?.discount,
        startDate: dayjs(discount?.startDate),
        startTime: dayjs(discount?.startDate),
        endDate: dayjs(discount?.endDate),
        endTime: dayjs(discount?.endDate),
        image: discount?.image,
        product: discount?.product?._id,
        subCategory: discount?.subcategory?._id,
      });
    }
  }, [discount, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            {/* Discount Type */}
            <InputLabel
              sx={{ textAlign: "initial !important" }}
              className="label"
              htmlFor="discount-type-select-label"
              id="discount-type-select-label"
            >
              {t("app.DiscountType")}
            </InputLabel>
            <Controller
              name="type"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("app.DiscountRequired"),
                },
              }}
              render={({ field }) => (
                <Select
                  sx={{ borderRadius: "12px" }}
                  id="discount-type-select"
                  labelId="discount-type-select-label"
                  error={!!errors?.type}
                  {...field}
                  fullWidth
                >
                  {StoreDiscountTypes()?.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText error={!!errors.type}>
              {errors.type?.message || null}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} md={6}>
            {/* Discount Amount */}
            <Controller
              name="discount"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("app.DiscountAmountRequired"),
                },
                pattern: {
                  value: PercentageNumber,
                  message: t("app.AmountDiscr"),
                },
              }}
              render={({ field }) => (
                <>
                  <InputLabel
                    sx={{ textAlign: "initial !important" }}
                    className="label"
                    htmlFor={field.name}
                  >
                    {t("app.DiscountAmount")}
                  </InputLabel>
                  <TextField
                    className="textField"
                    {...field}
                    inputProps={{
                      inputMode: "numeric",
                    }}
                    fullWidth
                    type="number"
                    placeholder="10%"
                    error={!!errors.discount}
                  />
                </>
              )}
            />
            <FormHelperText error={!!errors.discount}>
              {errors.discount?.message}
            </FormHelperText>
          </Grid>

          {typeChange === StoreDiscountTypesEnum.SUB_CATEGORY_DISCOUNT && (
            <>
              <Grid item xs={12}>
                {/* Sub Category */}
                <SelectInput
                  name="subCategory"
                  size="medium"
                  inputId="sub-category-select-label"
                  label={t("app.SubCategory")}
                  control={control}
                  options={optionsSubCategories}
                  error={errors?.subCategory}
                  rules={{
                    required: {
                      value: true,
                      message: t("errors.required"),
                    },
                  }}
                />
              </Grid>
            </>
          )}

          {typeChange === StoreDiscountTypesEnum.PRODUCT_DISCOUNT && (
            <Grid item xs={12}>
              <SelectInput
                name="product"
                size="medium"
                inputId="product-select-label"
                label={t("app.Product")}
                control={control}
                options={optionsProduct}
                error={errors?.product}
                rules={{
                  required: {
                    value: true,
                    message: t("errors.required"),
                  },
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            {/* start date */}

            <DatePickerInput
              name="startDate"
              label={t("app.StartDate")}
              rules={{
                required: {
                  value: true,
                  message: t("app.StartDateRequired"),
                },
              }}
              control={control}
              error={errors?.startDate}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {/* start time */}
            <TimePickerInput
              name="startTime"
              label={t("app.StartTime")}
              control={control}
              // rules={{
              //   required: {
              //     value: true,
              //     message: t("app.StartTimeRequired"),
              //   },
              // }}
              error={errors?.startTime}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {/* end date */}
            <DatePickerInput
              name="endDate"
              label={t("app.EndDate")}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("app.EndDateRequired"),
                },
              }}
              error={errors?.endDate}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {/* end date time */}
            <TimePickerInput
              name="endTime"
              label={t("app.EndTime")}
              control={control}
              // rules={{
              //   required: {
              //     value: true,
              //     message: t("app.EndTimeRequired"),
              //   },
              // }}
              error={errors?.endTime}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel
              sx={{ textAlign: "initial !important" }}
              className="label"
              variant="standard"
            >
              {t("app.AddDiscountImagee")}
            </InputLabel>
            <FormControl fullWidth>
              <FileUploader
                accept="image/*"
                handleChange={handleImageChange}
                types={["JPEG", "PNG", "GIF"]}
              >
                <div className="file-uploader-body">
                  <button className="btn">{t("product.AddFiles")} </button>
                  {watchImage instanceof File ? (
                    <p>
                      {t("app.FileName")}: {watchImage?.name}
                    </p>
                  ) : (
                    <img src={watchImage} height="60" alt="discount" />
                  )}

                  {watchImage ? null : (
                    <p>{t("product.Accepts")} .gif, .png and .jpg</p>
                  )}
                </div>
              </FileUploader>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                sx={{ mx: 3 }}
                onClick={closeModal}
                type="button"
                className="formButton"
                size="large"
                variant="outlined"
              >
                {t("product.Cancel")}
              </Button>

              <Button
                className="buttonHandleSubmit"
                type="submit"
                size="large"
                variant="contained"
                disabled={isLoading}
              >
                {t("app.EditDiscount")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
