import instance from "../../../utils/axios.util";
import { resetPasswordDto } from "../../models";

export async function ResetPassword(body: resetPasswordDto) {
  try {
    const res = await instance.patch("/reset-password", { password: body.password, confirmPassword: body.confirmPassword }, { headers: { Authorization: `Bearer ${body.token}` } });
    return res.data;
  } catch (error) {
    throw error;
  }
}
