import React from "react";
import { Grid, Box } from "@mui/material";
import { CgFileDocument } from "react-icons/cg";
import { TextInput } from "../../custom-inputs";
import { foodFormFields } from "./food-fields";

export const BasicDetailsForm = ({ control, errors, t }: any) => {
  const fields = foodFormFields(t, errors);

  return (
    <Grid
      container
      rowSpacing={3}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        marginTop: "20px",
      }}
    >
      <Grid item className="header" md={12} xs={12}>
        <CgFileDocument />
        {t("product.BasicDetails")}
      </Grid>

      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          paddingRight={3}
          paddingLeft={3}
          marginTop={2}
          marginBottom={2}
        >
          {fields.map((field:any, index:number) => (
            <Grid item xs={12} md={field.multiline ? 12 : 4} key={index}>
              <TextInput
                inputId={field.id}
                name={field.name}
                label={field.label}
                rules={field.rules}
                control={control}
                error={field.error}
                type={field.type}
                multiline={field.multiline}
                rows={field.rows}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Grid>
  );
};
